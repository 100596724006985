import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';

function TagInput({ tags: initialTags, onTagsChange, maxWidth }) {
    const [tags, setTags] = useState(initialTags || []);
    const [inputValue, setInputValue] = useState('');

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
    };

    const handleInputTags = (event) => {
        if (event.key && event.key !== 'Enter') return;
        if (inputValue.trim() !== '') {
            const newTags = [...tags, inputValue.trim()];
            setTags(newTags);
            onTagsChange(newTags);
            setInputValue('');
        }
    };

    const handleDeleteTag = (tagToDelete) => {
        const newTags = tags.filter((tag) => tag !== tagToDelete);
        setTags(newTags);
        onTagsChange(newTags);
    };

    return (
        <div>
            <TextField
                className={'tag-input'}
                label="Добавить значение"
                value={inputValue}
                onChange={handleInputChange}
                onBlur={handleInputTags}
                onKeyDown={handleInputTags}
                size="small"
                inputProps={{
                    style: { width: '172px', backgroundColor: 'white' },
                    maxLength: 20,
                    fontSize: '12px',
                }}
                InputLabelProps={{
                    style: {
                        fontSize: '15px'
                    },
                }}
            />
            <Stack spacing={1} direction="row" className="d-flex flex-wrap" style={{ maxWidth: maxWidth || '340px' }}>
                {tags && tags.map((tag, index) => (
                    <Chip
                        className="mt-2"
                        key={index}
                        label={tag}
                        onDelete={() => handleDeleteTag(tag)}
                        size="small"
                    />
                ))}
            </Stack>
        </div>
    );
}

export default TagInput;
import PropTypes from "prop-types";
import React from "react";
import { Modal, ModalBody } from "reactstrap";

const AddModal = ({ show, onAddClick, onCloseClick, text='Подтвердите добавление.', actionText='Добавить' }) => {
    return (
        <Modal isOpen={show} toggle={onCloseClick} centered={true}>
            <ModalBody className="py-3 px-5">
                <div className="mt-2 text-center">
                    <lord-icon
                        src="https://cdn.lordicon.com/nocovwne.json"
                        trigger="loop"
                        colors="primary:#0ab39c,secondary:#f06548"
                        style={{ width: "100px", height: "100px" }}
                    >
                    </lord-icon>
                    <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                        <h4>Внимание</h4>
                        <p className="text-muted mx-4 mb-0">
                            {text}
                        </p>
                    </div>
                </div>
                <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                    <button
                        type="button"
                        className="btn w-sm btn-light"
                        data-bs-dismiss="modal"
                        onClick={onCloseClick}
                    >
                        Закрыть
                    </button>
                    <button
                        type="button"
                        className="btn w-sm btn-success "
                        id="delete-record"
                        onClick={onAddClick}
                    >
                        {actionText}
                    </button>
                </div>
            </ModalBody>
        </Modal>
    );
};

AddModal.propTypes = {
    onCloseClick: PropTypes.func,
    onAddClick: PropTypes.func,
    show: PropTypes.any,
};

export default AddModal;
import React, {Fragment, useEffect, useState} from "react";
import PropTypes from "prop-types";
import {
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
  useRowSelect
} from "react-table";
import { Table, Row, Col, Button, Input, CardBody } from "reactstrap";
import { DefaultColumnFilter } from "./filters";
import {
  ProductsGlobalFilter,
  CustomersGlobalFilter,
  OrderGlobalFilter,
  ContactsGlobalFilter,
  CompaniesGlobalFilter,
  LeadsGlobalFilter,
  CryptoOrdersGlobalFilter,
  InvoiceListGlobalSearch,
  TicketsListGlobalFilter,
  NFTRankingGlobalFilter,
  TaskListGlobalFilter
} from "../../Components/Common/GlobalSearchFilter";
import { Link } from "react-router-dom";

// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  isCustomerFilter,
  isOrderFilter,
  isContactsFilter,
  isCompaniesFilter,
  isCryptoOrdersFilter,
  isInvoiceListFilter,
  isTicketsListFilter,
  isNFTRankingFilter,
  isTaskListFilter,
  isProductsFilter,
  isLeadsFilter
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <React.Fragment>
      <CardBody className="border border-dashed border-end-0 border-start-0">
        <form>
          <Row className="g-3" style={{flexDirection: 'row-reverse'}}>
            <Col>
              <div className={(isProductsFilter || isContactsFilter || isCompaniesFilter || isNFTRankingFilter) ? "search-box me-2 mb-2 d-inline-block" : "search-box me-2 mb-2 d-inline-block col-12"}>
                <input
                  onChange={(e) => {
                    setValue(e.target.value);
                    onChange(e.target.value);
                  }}
                  id="search-bar-0"
                  type="text"
                  className="form-control bg-light border-light search /"
                  placeholder={`${count} Поиск...`}
                  value={value || ""}
                />
                <i className="bx bx-search-alt search-icon"></i>
              </div>
            </Col>
            {isProductsFilter && (
              <ProductsGlobalFilter />
            )}
            {isCustomerFilter && (
              <CustomersGlobalFilter />
            )}
            {isOrderFilter && (
              <OrderGlobalFilter />
            )}
            {isContactsFilter && (
              <ContactsGlobalFilter />
            )}
            {isCompaniesFilter && (
              <CompaniesGlobalFilter />
            )}
            {isLeadsFilter && (
              <LeadsGlobalFilter />
            )}
            {isCryptoOrdersFilter && (
              <CryptoOrdersGlobalFilter />
            )}
            {isInvoiceListFilter && (
              <InvoiceListGlobalSearch />
            )}
            {isTicketsListFilter && (
              <TicketsListGlobalFilter />
            )}
            {isNFTRankingFilter && (
              <NFTRankingGlobalFilter />
            )}
            {isTaskListFilter && (
              <TaskListGlobalFilter />
            )}
          </Row>
        </form>
      </CardBody>

    </React.Fragment>
  );
}


const TableContainer = ({
  columns,
  data,
  isGlobalSearch,
  isGlobalFilter,
  isProductsFilter,
  isCustomerFilter,
  isOrderFilter,
  isContactsFilter,
  isCompaniesFilter,
  isLeadsFilter,
  isCryptoOrdersFilter,
  isInvoiceListFilter,
  isTicketsListFilter,
  isNFTRankingFilter,
  isTaskListFilter,
  isAddOptions,
  isAddUserList,
  handleOrderClicks,
  handleUserClick,
  handleCustomerClick,
  isAddCustList,
  customPageSize,
  customRow,
  tableClass,
  theadClass,
  trClass,
  thClass,
  divClass,
    //
  serverCount,
  serverNext,
  serverPrevious,
  onPageChange,
  currentPage,
  setCurrentPage
}) => {
  // -------------------------------------------------------------------------------------------------------------------
  const [totalPages, setTotalPages] = useState(null);
  const [pageItems, setPageItems] = useState([]);

  useEffect(() => {
    if (serverCount !== undefined) {
      // Calculate total pages based on server count and customPageSize
      setTotalPages(Math.ceil(serverCount / customPageSize));
    }
  }, [serverCount, customPageSize]);

  useEffect(()=>{
    if (totalPages) {
      setPageItems(Array.from({length: totalPages}, (_, index) => index));
    }else{
      setPageItems([]);
    }
  }, [totalPages])

  const handlePageClick = (pageNumber) => {
    // Call the onPageChange callback function with the selected page number
    if (!onPageChange) {
      setCurrentPage(pageNumber);
      gotoPage(pageNumber-1);
    }else{
      onPageChange({page: pageNumber});
    }
  };

  // -------
  const maxVisiblePages = 5; // You can adjust this value as needed
  const startPage = Math.max(0, currentPage - 1 - Math.floor(maxVisiblePages / 2));
  const endPage = Math.min(pageItems.length - 1, startPage + maxVisiblePages - 1);
  const pageButtons = []
  if (startPage > 0) {
      pageButtons.unshift(
          <React.Fragment key="startEllipsis">
              <li className="page-item disabled">
                  <span className="page-link">...</span>
              </li>
          </React.Fragment>
      );
  }
  pageButtons.unshift(
    <React.Fragment key="page1">
        <li className="page-item">
            <Link to="#" className={currentPage === 1 ? "page-link active" : "page-link"}
                  onClick={() => handlePageClick(1)}>1</Link>
        </li>
    </React.Fragment>
  );
  for (let i = startPage+1; i <= endPage-1; i++) {
      pageButtons.push(
          <React.Fragment key={i}>
              <li className="page-item">
                  <Link to="#" className={currentPage === pageItems[i] + 1 ? "page-link active" : "page-link"}
                        onClick={() => handlePageClick(pageItems[i] + 1)}>{pageItems[i] + 1}</Link>
              </li>
          </React.Fragment>
      );
  }
  if (endPage < pageItems.length - 1) {
      pageButtons.push(
          <React.Fragment key="endEllipsis">
              <li className="page-item disabled">
                  <span className="page-link">...</span>
              </li>
          </React.Fragment>
      );
  }
  if (pageItems.length > 0) {
    pageButtons.push(
        <React.Fragment key="lastPage">
          <li className="page-item">
            <Link
                to="#"
                className={currentPage === totalPages ? "page-link active" : "page-link"}
                onClick={() => handlePageClick(totalPages)}
            >
              {totalPages}
            </Link>
          </li>
        </React.Fragment>
    );
  }
  // -------------------------------------------------------------------------------------------------------------------
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn: { Filter: DefaultColumnFilter },
      initialState: {
        pageIndex: 0, pageSize: customPageSize, selectedRowIds: 0, sortBy: [
          {
            desc: true,
          },
        ],
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect
  );

  const generateSortingIndicator = (column) => {
    return column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : "";
  };

  const onChangeInSelect = (event) => {
    setPageSize(Number(event.target.value));
  };
  const onChangeInInput = (event) => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0;
    gotoPage(page);
  };

  const isHandheld = window.matchMedia("(max-width: 768px)").matches;

  return (
    <Fragment>
      <Row className="mb-3" >
        {isGlobalSearch && (
          <Col md={1}>
            <select
              className="form-select"
              value={pageSize}
              onChange={onChangeInSelect}
            >
              {[10, 20, 30, 40, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </Col>
        )}
        {isGlobalFilter && (
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
            isProductsFilter={isProductsFilter}
            isCustomerFilter={isCustomerFilter}
            isOrderFilter={isOrderFilter}
            isContactsFilter={isContactsFilter}
            isCompaniesFilter={isCompaniesFilter}
            isLeadsFilter={isLeadsFilter}
            isCryptoOrdersFilter={isCryptoOrdersFilter}
            isInvoiceListFilter={isInvoiceListFilter}
            isTicketsListFilter={isTicketsListFilter}
            isNFTRankingFilter={isNFTRankingFilter}
            isTaskListFilter={isTaskListFilter}
          />
        )}
        {isAddOptions && (
          <Col sm="7">
            <div className="text-sm-end">
              <Button
                type="button"
                color="success"
                className="btn-rounded  mb-2 me-2"
                onClick={handleOrderClicks}
              >
                <i className="mdi mdi-plus me-1" />
                Add New Order
              </Button>
            </div>
          </Col>
        )}
        {isAddUserList && (
          <Col sm="7">
            <div className="text-sm-end">
              <Button
                type="button"
                color="primary"
                className="btn mb-2 me-2"
                onClick={handleUserClick}
              >
                <i className="mdi mdi-plus-circle-outline me-1" />
                Create New User
              </Button>
            </div>
          </Col>
        )}
        {isAddCustList && (
          <Col sm="7">
            <div className="text-sm-end">
              <Button
                type="button"
                color="success"
                className="btn-rounded mb-2 me-2"
                onClick={handleCustomerClick}
              >
                <i className="mdi mdi-plus me-1" />
                New Customers
              </Button>
            </div>
          </Col>
        )}
      </Row>


      <div className={divClass}>
        <Table hover {...getTableProps()} className={tableClass}>
          <thead className={theadClass}>
            {headerGroups.map((headerGroup) => (
              <tr className={trClass} key={headerGroup.id}  {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th key={column.id} className={thClass} {...column.getSortByToggleProps()} title='Сортирование'>
                    {column.render("Header")}
                    {generateSortingIndicator(column)}
                    {/* <Filter column={column} /> */}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <Fragment key={row.getRowProps().key}>
                  <tr>
                    {row.cells.map((cell) => {
                      return (
                        <td key={cell.id} {...cell.getCellProps()}>
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                </Fragment>
              );
            })}
            {customRow?.colspan && customRow?.value !== null &&
                <tr style={{borderBottom: 0}}>
                  <td style={{borderBottom: 0}} colSpan={customRow.colspan}><b>Итого:</b></td>
                  <td style={{borderBottom: 0}} colSpan={10}><b>{customRow.value}</b></td>
                </tr>
            }
          </tbody>
        </Table>
      </div>

      
      <Row className="align-items-center mt-2 g-3 text-center text-sm-start" >
        <div className="col-sm">
            <div className="text-muted">Показано<span className="fw-semibold ms-1">{page.length}</span> из <span className="fw-semibold">{serverCount || data.length}</span> результатов
            </div>
        </div>
        <div className="col-sm-auto">
          <ul className="pagination pagination-separated pagination-md justify-content-center justify-content-sm-start mb-0">
            {!isHandheld && (
                <li className={serverPrevious
                    ? (!serverPrevious ? "page-item disabled" : "page-item")
                    : (!canPreviousPage ? "page-item disabled" : "page-item")}>
                  <Link to="#"
                        className="page-link"
                        onClick={serverPrevious || !onPageChange ? () => handlePageClick(currentPage - 1) : previousPage}>Назад</Link>
                </li>
            )}


            {totalPages ?
                // pageItems.map((item, key) => (
                //     <React.Fragment key={key}>
                //       <li className="page-item">
                //         <Link to="#" className={currentPage === item + 1 ? "page-link active" : "page-link"}
                //               onClick={() => handlePageClick(item + 1)}>{item + 1}</Link>
                //       </li>
                //     </React.Fragment>
                // ))
                pageButtons
                :
                pageOptions.map((item, key) => (
                    <React.Fragment key={key}>
                      <li className="page-item">
                        <Link to="#" className={pageIndex === item ? "page-link active" : "page-link"}
                              onClick={() => gotoPage(item)}>{item + 1}</Link>
                      </li>
                    </React.Fragment>
                ))
            }
            {!isHandheld && (
              <li className={serverNext
                  ? (!serverNext ? "page-item disabled" : "page-item")
                  : (!canNextPage ? "page-item disabled" : "page-item")}>
                <Link to="#"
                      className="page-link"
                      onClick={serverNext || !onPageChange ? () => handlePageClick(currentPage + 1) : nextPage}
                >Вперёд {nextPage}</Link>
              </li>
            )}
          </ul>
        </div>
      </Row>
    </Fragment>
  );
};

TableContainer.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default TableContainer;
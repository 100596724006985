import React, { useEffect, useState, useCallback, useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import * as moment from "moment";
import Flatpickr from "react-flatpickr";
import styles from "./CRM.module.css"
import { Russian } from "flatpickr/dist/l10n/ru.js"

import {
  Button,
  Col,
  Container,
  Row,
  Card,
  CardHeader,
  CardBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  ModalFooter,
  Table,
  FormFeedback,
} from "reactstrap";
import Select from "react-select";

import DeleteModal from "../../Components/Common/DeleteModal";

// import WidgetsTask from "./WidgetsTask";

import TableContainer from "../../Components/Common/TableContainer";

// Formik
import * as Yup from "yup";
import { useFormik } from "formik";

import Loader from "../../Components/Common/Loader";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Export Modal
import ExportCSVModal from "../../Components/Common/ExportCSVModal";

import {
  getStorage,
  getStoragesApi,
  getRemainsApi,
  deleteRemainsApi,
  updateRemainsApi, getAccountsApi,
} from "../../helpers/backend_helper";
import CreateOrder from "../Orders/CreateOrder";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import PrintButton from "../Prints/PrintButton";
import ProductDropdown from "./Helpers/ProductDropdown";
import SelectedProducts from "./Helpers/SelectedProducts";
import TagInput from "../../Components/Common/TagInput";


const CrmContacts = () => {
  const { storageId } = useParams();
  const [loading, setLoading] = useState(true);

  const [isEdit, setIsEdit] = useState(false);
  const [isEdited, setIsEdited] = useState(false);
  const [contact, setContact] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [totalSelfCost, setTotalSelfCost] = useState(0);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [storage, setStorage] = useState({});
  const [storages, setStorages] = useState([]);

  const [filtering, setFiltering] = useState(false);
  const [filters, setFilters] = useState({});
  const [accountsApi, setAccountsApi] = useState({});
  const [currentPage, setCurrentPage] = useState(1);

  const hasPermissions = (permissions) => {
    return accountsApi.results && accountsApi.current && permissions.includes(accountsApi.results[accountsApi.current].access);
  };
  const [printCols, setPrintCols] = useState([]);

  const hasPermissionsCallback = useCallback((permissions) => {
    return accountsApi.results && accountsApi.current && permissions.includes(accountsApi.results[accountsApi.current].access);
  }, [accountsApi]);
  const getAccessObjects = () => {
    return String(accountsApi?.results && accountsApi.current && accountsApi?.results[accountsApi.current]?.access_objects);
  };

  useEffect(() => {
    const fetchAccounts = () => {
      getAccountsApi()
      .then((data) => {
        setAccountsApi(data);
      })
      .catch(() => {
        // toast.error("Не удалось получить список аккаунтов", {
        //   position: "top-right",
        // });
      });
  };
  fetchAccounts();
  }, []);


  /*useEffect(() => {
    // let data = {}

    // if ('dates' in filters) {
    //   Object.keys(filters['dates']).forEach(key => {
    //     data[key] = filters['dates'][key];
    //   });
    // }

    if (storageId) {
      // data.id = storageId;
      // getStorage(data)
      //   .then((data) => {
      //     setStorage(data);
      //     setAccounts(data.storage_products);
      //   })
      //   .catch((error) => {
      //     toast.error("Не удалось получить данные об остатках", {
      //       position: "top-right",
      //     });
      //   });
    }else{
      // if ('storage' in filters) {
      //   data.storage = filters.storage;
      // }
      /!*getRemainsApi(data)
        .then((data) => {
          setStorage({});
          setAccounts(data.data);
          setTotalSelfCost(data.total_self_cost);
        })
        .catch((error) => {
          toast.error("Не удалось получить данные об остатках", {
            position: "top-right",
          });
        });*!/
    }
  }, [filters]);*/


  const toggleFiltering = () => {
    if (filtering) {
      setFiltering(false);
    } else {
      setFiltering(true);
    }
  };

  const fetchRemains = () => {
    let data = {};
    if ('dates' in filters) {
      Object.keys(filters['dates']).forEach(key => {
        data[key] = filters['dates'][key];
      });
    }

    if (storageId) {
      data.id = storageId;
      getStorage(data)
        .then((data) => {
          setStorage(data);
          setAccounts(data.storage_products);
        })
        .catch((error) => {
          toast.error("Не удалось получить данные об остатках", {
            position: "top-right",
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }else{
      if ('storage' in filters) {
        data.storage = filters.storage;
      }
      getRemainsApi(data)
        .then((data) => {
          setStorage({});
          setAccounts(data.data);
          setTotalSelfCost(data.total_self_cost);
        })
        .catch((error) => {
          toast.error("Не удалось получить данные об остатках", {
            position: "top-right",
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    getStoragesApi()
      .then((response) => {
        setStorages(response);
      })
      .catch((error) => {});

    fetchRemains();
  }, [storageId, filters]);

  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };
  const handleProductsChange = (tags) => {
    setSearchTerm(tags);
  };
  useEffect(() => {
    // const filteredAccounts = accounts.filter((account) =>
    //     account.product_data.title.toLowerCase().includes(searchTerm.trim().toLowerCase()) ||
    //     account.product_data.code.toLowerCase().includes(searchTerm.trim().toLowerCase())
    // );
    const filteredAccounts = accounts.filter((account) =>
        searchTerm.length === 0 || searchTerm.some(tag =>
            account?.product_data?.title.toLowerCase().includes(tag.toLowerCase()) ||
            accounts?.product_data?.code.toLowerCase().includes(tag.toLowerCase())
        )
    );
    setFilteredProducts(filteredAccounts || accounts);
  }, [accounts, searchTerm])


  //delete Contact
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteModalMulti, setDeleteModalMulti] = useState(false);
  const [createMulti, setCreateMulti] = useState(false);
  const [isCreateButton, setIsCreateButton] = useState(false);

  const create = () => {
    const checkall = document.getElementById("checkBoxAll");
    selectedCheckBoxDelete.forEach((element) => {
      // dispatch(deleteTask(element.value));  // delete with checkboxes
      setTimeout(() => {
        toast.clearWaitingQueue();
      }, 3000);
    });
    setIsCreateButton(false);
    checkall.checked = false;
  };

  const createbox = () => {
    const ele = document.querySelectorAll(".taskCheckBox:checked");
    ele.length > 0 ? setIsCreateButton(true) : setIsCreateButton(false);
    setSelectedCheckBoxDelete(ele);
  };
  const [modal, setModal] = useState(false);
  const [modalTask, setModalTask] = useState(false);
  const [errors, setErrors] = useState({});

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      setContact(null);
    } else {
      setErrors({});
      validation.resetForm();
      setModal(true);
      setTag([]);
      setAssignTag([]);
    }
  }, [modal]);

  const toggleTask = useCallback(() => {
    if (modalTask) {
      setModalTask(false);
      setContact(null);
    } else {
      setModalTask(true);
      setTag([]);
      setAssignTag([]);
    }
  }, [modalTask]);

  // Delete Data
  const handleDeleteContact = () => {
    if (contact) {
      deleteRemainsApi(contact)
        .then(() => {
          setAccounts((prevAccount) =>
            prevAccount.filter((item) => item.id !== contact.id)
          );
          toast.success("Остаток был успешно удалён.", {
            position: "top-right",
          });
        })
        .catch((error) => {
          // console.error(error);
          toast.error("Не удалось удалить остаток.", { position: "top-right" });
        });
      // dispatch(onDeleteContact(contact._id));
      setDeleteModal(false);
    }
  };

  const onClickDelete = (contact) => {
    setContact(contact);
    setDeleteModal(true);
  };

  // Add Data
  const handleContactClicks = () => {
    // Create Contact
    validation.resetForm();
    setErrors({});
    setContact("");
    setIsEdit(false);
    setIsEdited(false);
    toggle();
  };

  // Date & Time Format

  const dateFormat = () => {
    var d = new Date(),
      months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
    return d.getDate() + " " + months[d.getMonth()] + ", " + d.getFullYear();
  };
  const dateformate = (e) => {
    const date = e.toString().split(" ");
    const joinDate = (date[2] + " " + date[1] + ", " + date[3]).toString();
    setDate(joinDate);
  };

  //createTask
  const [task, setTask] = useState([]);
  const valid = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      taskId: (task && task.taskId) || "",
      project: (task && task.project) || "",
      task: (task && task.task) || "",
      creater: (task && task.creater) || "",
      dueDate: (task && task.dueDate) || "",
      status: (task && task.status) || "New",
      priority: (task && task.priority) || "High",
      subItem: (task && task.subItem) || [],
    },
    taskSchema: Yup.object({
      taskId: Yup.string().required("Please Enter Task Id"),
      project: Yup.string().required("Please Enter Project Name"),
      task: Yup.string().required("Please Enter Your Task"),
      creater: Yup.string().required("Please Enter Creater Name"),
      // dueDate: Yup.string().required("Please Enter Due Date"),
      status: Yup.string().required("Please Enter Status"),
      priority: Yup.string().required("Please Enter Priority"),
      subItem: Yup.array().required("Please Enter"),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updatedTask = {
          _id: task ? task._id : 0,
          taskId: values.taskId,
          project: values.project,
          task: values.task,
          creater: values.creater,
          dueDate: date,
          status: values.status,
          priority: values.priority,
          subItem: values.subItem,
        };
        // update customer
        // dispatch(updateTask(updatedTask));  // UPDATE TASK
        valid.resetForm();
      } else {
        const newTask = {
          _id: (Math.floor(Math.random() * (30 - 20)) + 20).toString(),
          taskId: values["taskId"],
          project: values["project"],
          task: values["task"],
          creater: values["creater"],
          dueDate: date,
          status: values["status"],
          priority: values["priority"],
          subItem: values["subItem"],
        };
        // save new customer
        // dispatch(addNewTask(newTask));  // ADD NEW TASK
        task.resetForm();
      }
      toggleTask();
    },
  });
  const defaultdate = () => {
    let d = new Date(),
      months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
    return (
      d.getDate() +
      " " +
      months[d.getMonth()] +
      ", " +
      d.getFullYear()
    ).toString();
  };
  const [date, setDate] = useState(defaultdate());

  // Create a function to update an element in accounts
  const updateItemInStorageProducts = (updatedItem) => {
    setAccounts((prevAccounts) => {
      return prevAccounts.map((item) => {
        if (item.id === updatedItem.id) {
          // Update the item with the updatedItem data
          return updatedItem;
        }
        return item;
      });
    });
  };

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      product: (contact && contact.product) || "",
      remaining: (contact && contact.remaining) || "1",
    },
    validationSchema: Yup.object({
      product: Yup.string().required("Пожалуйста Выберите Товар"),
      remaining: Yup.number().required("Пожалуйста введите Количество"),
    }),
    validateOnChange: true,

    validate: (values) => {
      const errors = {};

      if (!values.remaining) {
        errors.remaining = "Пожалуйста введите Количество";
      } else if (values.remaining < 0) {
        errors.remaining = "Количество не может быть отрицательным";
      } else if (values.remaining > 100) {
        errors.remaining = "Количество не может быть больше 100";
      }

      return errors;
    },
    onSubmit: (values) => {
      if (isEdit) {
        const updateStorageProductData = {
          id: contact ? contact._id : 0,
          // img: values.img,
          // username: values.username,
          // token: values.token,
          // email: values.email,
          // phone: values.phone,
          // password: values.password,
          // joined: dateFormat(),
          // time: timeFormat(),
          // tags: assignTag,
        };
        // update StorageProduct

        updateRemainsApi(updateStorageProductData)
          .then((updateStorageProductData) => {
            // Call the function to update the element in teamList
            updateItemInStorageProducts(updateStorageProductData);
            setModal(false);
            toast.success("Остаток успешно обновлён", {
              position: "top-right",
            });
          })
          .catch((error) => {
            // console.error(error);
            toast.error("Не удалось обновить остаток", {
              position: "top-right",
            });
          });
      } else {
        setContact(null);
        setIsEdit(false);

        const newAccount = {
          storageId,

          // img: values["img"],
          // username: values["username"],
          // token: values["token"],
          // email: values["email"],
          // phone: values["phone"],
          // password: values["password"],
          // joined: dateFormat(),
          // time: timeFormat(),
          // tags: assignTag,
        };
        // save new Contact

        // createStorageProductApi(newAccount)
        //   .then((createdAccount) => {
        //     // Call the function to update the element in teamList
        //     let updated = [createdAccount, ...accounts]
        //     setAccounts(updated);
        //     setModal(false);
        //   })
        //   .catch((error) => {
        //     // console.error(error);
        //     toast.error('Error creating account', { position: "top-right" })
        //   });

        // validation.resetForm();
      }
      if (!errors) {
        if (isEdit) {
          setIsEdit(false);
        }
        validation.resetForm();
        toggle();
      }
    },
  });

  // Update Data
  const handleContactClick = useCallback(
    (arg) => {
      const contact = arg;

      setContact({
        _id: contact.id,
        product: contact.product,
        remaining: contact.remaining,
        // img: contact.img,
        // username: contact.username,
        // token: contact.token,
        // email: contact.email,
        // phone: contact.phone,
        // password: contact.password,
        // joined: contact.joined,
        // time: contact.time,
        // tags: contact.tags,
      });

      setIsEdit(true);
      toggle();
    },
    [toggle]
  );

  // Node API
  // useEffect(() => {
  //   if (isContactCreated) {
  //     setContact(null);
  //     dispatch(onGetContacts());
  //   }
  // }, [
  //   dispatch,
  //   isContactCreated,
  // ]);

  const handleValidDate = (date) => {
    const date1 = moment(new Date(date)).format("DD MMM Y");
    return date1;
  };

  const handleValidTime = (time) => {
    const time1 = new Date(time);
    const getHour = time1.getUTCHours();
    const getMin = time1.getUTCMinutes();
    const getTime = `${getHour}:${getMin}`;
    var meridiem = "";
    if (getHour >= 12) {
      meridiem = "PM";
    } else {
      meridiem = "AM";
    }
    const updateTime =
      moment(getTime, "hh:mm").format("hh:mm") + " " + meridiem;
    return updateTime;
  };

  // Checked All
  const checkedAll = useCallback(() => {
    const checkall = document.getElementById("checkBoxAll");
    const ele = document.querySelectorAll(".contactCheckBox");

    if (checkall.checked) {
      ele.forEach((ele) => {
        ele.checked = true;
      });
    } else {
      ele.forEach((ele) => {
        ele.checked = false;
      });
    }
    // deleteCheckbox();  // TODO
  }, []);

  // Delete Multiple
  const [selectedCheckBoxDelete, setSelectedCheckBoxDelete] = useState([]);
  const [isMultiDeleteButton, setIsMultiDeleteButton] = useState(false);

  const deleteMultiple = async () => {
    const checkall = document.getElementById("checkBoxAll");

    for (const element of selectedCheckBoxDelete) {
      await deleteRemainsApi({ id: element.value })
        .then(() => {
          // setAccounts((prevAccount) =>
          //   prevAccount.filter((item) => String(item.id) !== element.value)
          // );
        })
        .catch((error) => {
          toast.error("Не удалось удалить остаток", { position: "top-right" });
        });

      setTimeout(() => {
        toast.clearWaitingQueue();
      }, 3000);
    }
    fetchRemains();
    setDeleteModal(false);
    toast.success("Выбранные остатки были успешно удалены", {
      position: "top-right",
    });
    setIsMultiDeleteButton(false);
    checkall.checked = false;
    checkedAll();
  };
  // const deleteMultiple = () => {
  //   const checkall = document.getElementById("checkBoxAll");
  //   selectedCheckBoxDelete.forEach((element) => {
  //     // console.warn(element.value);
  //       deleteRemainsApi({id:element.value})
  //         .then(() => {
  //           setAccounts((prevAccount) => prevAccount.filter((item) => item.id != element.value));
  //         })
  //         .catch((error) => {
  //           // toast.error('Не удалось удалить выбранные остатки.', { position: "top-right" })
  //         });
  //     setTimeout(() => {
  //       toast.clearWaitingQueue();
  //     }, 3000);
  //   });
  //   setIsMultiDeleteButton(false);
  //   checkall.checked = false;
  // };

  const deleteCheckbox = () => {
    const ele = document.querySelectorAll(".contactCheckBox:checked");
    ele.length > 0
      ? setIsMultiDeleteButton(true)
      : setIsMultiDeleteButton(false);
    setSelectedCheckBoxDelete(ele);
  };

  // Define a custom filter function for the "Status" column
  // const statusColumnFilter = ({
  //                               column: { filterValue, setFilter },
  //                             }) => {
  //   const onChange = useAsyncDebounce((value) => {
  //     setFilter(value || undefined);
  //   }, 200);
  //
  //   return (
  //       <input
  //           type="text"
  //           placeholder="Filter Status"
  //           value={filterValue || ''}
  //           onChange={(e) => {
  //             onChange(e.target.value);
  //           }}
  //       />
  //   );
  // };

  // Customber Column
  const columns = useMemo(() => {
      const baseColumns = [
        {
          Header: (
              <input
                  type="checkbox"
                  id="checkBoxAll"
                  className="form-check-input"
                  onClick={() => checkedAll()}
              />
          ),
          Cell: (cellProps) => {
            return (
                <input
                    type="checkbox"
                    className="contactCheckBox form-check-input"
                    value={cellProps.row.original.id}
                    // onChange={() => deleteCheckbox()}
                />
            );
          },
          id: "#",
        },
        {
          Header: "Товар",
          accessor: "product_data.title",
          filterable: true,
          Cell: (storage_product) => (
              <>
                <div
                    style={{
                      display: "flex",
                      justifyContent: "left",
                      alignItems: "center",
                    }}
                >
                  <div
                      style={{
                        height: "30px",
                        width: "35px",
                        minHeight: "30px",
                        minWidth: "35px",
                        marginRight: "20px",
                        verticalAlign: "middle",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                  >
                    {(storage_product.row.original.product_data.image ||
                        storage_product.row.original.product_data?.url_image) ? (
                        <img
                            src={storage_product.row.original.product_data.image ||
                                storage_product.row.original.product_data?.url_image}
                            style={{
                              width: "auto",
                              maxWidth: "100%",
                              maxHeight: "100%",
                            }}
                            alt="картинка товара"
                        />
                    ) : null}
                  </div>
                  <span
                      style={{lineHeight: '1.3', wordWrap: 'break-word', whiteSpace: 'normal'}}
                  >
                {storage_product.row.original.product_data.title}
              </span>

                </div>
              </>
          ),
        },
        {
          Header: "Артикул",
          accessor: "product_data.code",
          filterable: true,
          Cell: (storage_product) => (
              <>
                {/*<Link to={ "/products/" + storage_product.row.original.product + '/' }>*/}
                {/*  <span style={{color: '#5691b6'}}>*/}
                {storage_product.row.original.product_data.code}
                {/*</span>*/}
                {/*</Link>*/}
              </>
          ),
        },
        // {
        //     Header: "Продано",
        //     accessor: "product.sold",
        //     filterable: false,
        //     Cell: (storage_product) => (
        //         <>
        //     <span>
        //       {storage_product.row.original.product_data.sold}
        //     </span>
        //         </>
        //     ),
        // },
        {
          Header: "Остаток",
          accessor: "remaining",
          filterable: false,
          Cell: (storage_product) => (
              <>
                <span>{storage_product.row.original?.total_remaining || storage_product.row.original.remaining}</span>
              </>
          ),
        },
        // {
        //   Header: "Резерв",
        //   // accessor: "product",
        //   filterable: false,
        //   Cell: (storage_product) => (
        //     <>
        //       <small className="text-muted">
        //         {storage_product.row.original.reserve}
        //       </small>
        //     </>
        //   ),
        // },
        // {
        //   Header: "Доступно",
        //   // accessor: "product",
        //   filterable: false,
        //   Cell: (storage_product) => (
        //     <>
        //       <small className="text-muted">
        //         {storage_product.row.original.available}
        //       </small>
        //     </>
        //   ),
        // },
        // {
        //   Header: "Дней на складе",
        //   accessor: "days_is_storage",
        //   filterable: false,
        //   Cell: (storage_product) => (
        //       <>
        //         <span>{!storage_product.row.original?.total_remaining && storage_product.row.original.days_in_storage || '-'}</span>
        //       </>
        //   ),
        // },
        // {
        //   Header: "Email",
        //   accessor: "email",
        //   filterable: false,
        // },
        // {
        //   Header: "Password",
        //   accessor: "password",
        //   filterable: false,
        // },
        // {
        //   Header: "Phone",
        //   accessor: "phone",
        //   filterable: false,
        // },
        // {
        //   Header: "Status",
        //   accessor: "status",
        //   filterable: true,
        //   // Filter: statusColumnFilter, // Use the custom filter function
        //   Cell: (contact) => (
        //       <>
        //       <span className={`badge bg-${contact.row.original.status === 'ban' ? 'danger' : 'success'}-subtle text-${contact.row.original.status === 'ban' ? 'danger' : 'success'} me-1`}>
        //         {contact.row.original.status.toUpperCase()}
        //       </span>
        //       </>
        //   ),
        // },
        // {
        //   Header: "Farms",
        //   filterable: false,
        //   Cell: (contact) => (
        //       <>
        //         {contact.row.original.farm_tags.map((item, key) => (
        //             <span
        //                 className="badge bg-primary-subtle text-primary me-1"
        //                 key={key}
        //             >
        //         &nbsp;{item}&nbsp;
        //       </span>
        //         ))}
        //       </>
        //   ),
        // },
        // {
        //   Header: "Дата создания",
        //   Cell: (contact) => (
        //     <>
        //       {/*{handleValidDate(contact.row.original.last_contacted)},{" "}*/}
        //       {/*{contact.joined},{" "}*/}
        //       {/*{contact.row.original.joined}*/}
        //       <small className="text-muted">
        //         {/*{handleValidTime(contact.row.original.last_contacted)}*/}
        //         {contact.row.original.created}
        //       </small>
        //     </>
        //   ),
        // },
        // {
        //   Header: "Действие",
        //   Cell: (cellProps) => {
        //     return (
        //         <ul className="list-inline hstack gap-2 mb-0">
        //           {/*<li className="list-inline-item edit" title="Call">*/}
        //           {/*  /!*<a href={"/account-details/" + cellProps.row.original.id}>*!/*/}
        //           {/*  <Link to={ "/account-details/" + cellProps.row.original.id }>*/}
        //           {/*    <button className="btn btn-success">Open account</button>*/}
        //           {/*  </Link>*/}
        //           {/*  /!*</a>*!/*/}
        //           {/*</li>*/}
        //           <li className="list-inline-item">
        //             <UncontrolledDropdown>
        //               <DropdownToggle
        //                   href="#"
        //                   className="btn btn-soft-secondary btn-sm dropdown"
        //                   tag="button"
        //               >
        //                 <i className="ri-more-fill align-middle"></i>
        //               </DropdownToggle>
        //               <DropdownMenu className="dropdown-menu-end">
        //                 {/*<DropdownItem*/}
        //                 {/*  className="dropdown-item"*/}
        //                 {/*  href="#"*/}
        //                 {/*  onClick={() => {*/}
        //                 {/*    const contactData = cellProps.row.original;*/}
        //                 {/*    setInfo(contactData);*/}
        //                 {/*  }}*/}
        //                 {/*>*/}
        //                 {/*  <i className="ri-eye-fill align-bottom me-2 text-muted"></i>{" "}*/}
        //                 {/*  View*/}
        //                 {/*</DropdownItem>*/}
        //                 <DropdownItem
        //                     className="dropdown-item edit-item-btn"
        //                     href="#"
        //                     onClick={() => {
        //                       const contactData = cellProps.row.original;
        //                       handleContactClick(contactData);
        //                       setErrors({});
        //                       validation.resetForm();
        //                     }}
        //                 >
        //                   <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>{" "}
        //                   Редактировать
        //                 </DropdownItem>
        //                 <DropdownItem
        //                     className="dropdown-item remove-item-btn"
        //                     href="#"
        //                     onClick={() => {
        //                       const contactData = cellProps.row.original;
        //                       onClickDelete(contactData);
        //                     }}
        //                 >
        //                   <i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i>{" "}
        //                   Удалить
        //                 </DropdownItem>
        //               </DropdownMenu>
        //             </UncontrolledDropdown>
        //           </li>
        //         </ul>
        //     );
        //   },
        // },
      ]
      if (hasPermissionsCallback(['owner', 'admin'])) {
        baseColumns.splice(4, 0, {
          Header: "Себестоимость",
          accessor: "product_data.self_cost",
          filterable: false,
          Cell: (storage_product) => (
            <>
              <span>
                {storage_product.row.original.product_data.self_cost || "-" } {/*// && storage_product.row.original.product_data.self_cost.split('.')[0] || "-"}*/}
              </span>
            </>
          ),
        },
        {
          Header: "Сумма себестоимости",
          accessor: "total_self_cost",
          filterable: false,
          Cell: (storage_product) => (
            <>
              <span>
                {storage_product.row.original.total_self_cost || "-" }
                {/*{storage_product.row.original.product_data.self_cost &&
                storage_product.row.original.remaining
                  ? (
                      Number(
                        storage_product.row.original.product_data.self_cost
                      ) * Number(storage_product.row.original?.total_remaining || storage_product.row.original.remaining)
                    ).toFixed(2).split('.')[0]
                  : "-"}*/}
              </span>
            </>
          ),
        })
      }
      if (storageId) { // Проверяем, если storageId не пустой
        baseColumns.push({
          Header: "Дней на складе",
          accessor: "days_is_storage",
          filterable: false,
          Cell: (storage_product) => (
              <>
                <span>{!storage_product.row.original?.total_remaining && storage_product.row.original.days_in_storage || '-'}</span>
              </>
          ),
        });
      }
      return baseColumns;
    },
    [handleContactClick, checkedAll, hasPermissionsCallback, storageId]
  );

  const getColumnNames = (columns) => {
    return columns
        .filter(column => typeof column.Header === 'string')
        .map(column => column.Header);
  };

  useEffect(() => {
    setPrintCols(getColumnNames(columns));
  }, [columns])

  // Define your static options
  const options = [
    { label: "Option 1", value: "option1" },
    { label: "Option 2", value: "option2" },
    { label: "Option 3", value: "option3" },
  ];

  const [tag, setTag] = useState([]);
  const [assignTag, setAssignTag] = useState([]);

  // SideBar Contact Deatail
  const [info, setInfo] = useState([]);

  // Export Modal
  const [isExportCSV, setIsExportCSV] = useState(false);

  document.title = "CRMBEK : Остатки по складам";

  return (
    <React.Fragment>
      <div className="page-content">
        <ExportCSVModal
          show={isExportCSV}
          onCloseClick={() => setIsExportCSV(false)}
          // data={crmcontacts}
          data={[]}
        />
        <DeleteModal
          show={deleteModal}
          onDeleteClick={handleDeleteContact}
          onCloseClick={() => setDeleteModal(false)}
        />

        <DeleteModal
          show={deleteModalMulti}
          onDeleteClick={() => {
            deleteMultiple();
            setDeleteModalMulti(false);
          }}
          onCloseClick={() => setDeleteModalMulti(false)}
        />
        {/* HERE */}

        {/*<AddToFarmModal*/}
        {/*    show={addToFarmModalMulti}*/}
        {/*    // show={false}*/}
        {/*    onAddToFarmClick={() => {*/}
        {/*      // deleteMultiple();*/}
        {/*      // setAddToFarmModalMulti(false);*/}
        {/*    }}*/}
        {/*    // onCloseClick={() => setAddToFarmModalMulti(false)}*/}
        {/*/>*/}

        <Container fluid>
          {storageId && storage?.name
              ? <BreadCrumb pageTitle="Остатки" title={storage.name} />
              : <BreadCrumb title="Остатки" />
          }
          {/*<Row>*/}
          {/*  {farmId ? <WidgetsTask /> : null}*/}
          {/*</Row>*/}
          {hasPermissions(['owner']) || getAccessObjects().includes('products') ? (
          <Row className="pb-5"> {/*px-5*/}
            <Col lg={12}>
              <Card style={{marginBottom: 0}}>
                <CardHeader>
                  <div className="d-flex align-items-center flex-wrap gap-2">
                    <div className="flex-grow-1 d-flex align-items-center">
                      <div style={{ display: "flex" }}>
                        <Link to="/storages" style={{ width: "55px" }}>
                          {/*<div className='d-flex align-items-end gap-2'>*/}
                          <i className="ri-reply-fill" style={{fontSize: '16px', lineHeight: '0.8'}}></i>
                          <div style={{fontSize: '13px'}}>Склады</div>
                          {/*</div>*/}
                        </Link>
                        {/*<CreateOrder/>*/}
                        <button
                            className={`btn ${filtering ? 'btn-primary' : 'btn-outline-primary'} px-2 fs-14 ${styles.filter_button}`}
                            onClick={toggleFiltering}
                        >
                          Фильтры{" "}
                          <i className="ri-filter-2-line me-1 align-bottom"></i>
                        </button>
                        {/*<h5 style={{ margin: "0 15px 0 0" }} className="lh-1">*/}
                        {/*  Остатки*/}
                        {/*  {storage?.name ? " " + storage.name : ""}*/}
                        {/*</h5>*/}
                      </div>

                      {/*<input*/}
                      {/*  type="text"*/}
                      {/*  placeholder="Поиск по товарам"*/}
                      {/*  value={searchTerm}*/}
                      {/*  onChange={handleSearchChange}*/}
                      {/*/>*/}
                    </div>
                    {/*<CreateOrder/>*/}
                    {/*<button*/}
                    {/*  className="btn btn-info add-btn p-1"*/}
                    {/*  onClick={() => {*/}
                    {/*    // setModal(true);*/}
                    {/*    setIsEdit(false);*/}
                    {/*    setContact(null);*/}
                    {/*    setErrors({});*/}
                    {/*    validation.resetForm();*/}
                    {/*  }}*/}
                    {/*>*/}
                    {/*  <span style={{ fontSize: "14px" }}>*/}
                    {/*    <i className="ri-add-fill me-1 align-bottom fs-14"></i>Заказ*/}
                    {/*  </span>*/}
                    {/*</button>*/}
                    <div className="d-flex flex-wrap gap-2">
                      {/*<button*/}
                      {/*  className="btn btn-outline-primary add-btn me-2-bin-1-line p-1"*/}
                      {/*  onClick={() => {*/}
                      {/*    // setImportTokensModal(true);*/}
                      {/*  }}*/}
                      {/*>*/}
                      {/*  <img src="/printing.png" alt="printing" />*/}
                      {/*  <span style={{ marginLeft: "6px" }}>Печать</span>*/}
                      {/*</button>*/}

                      <PrintButton documentType={'remains'} button={
                        <button
                            className="btn btn-outline-primary add-btn me-2-bin-1-line p-1"
                        >
                          <img src="/printing.png" alt="printing" />
                          <span style={{ marginLeft: "6px" }}>Печать</span>
                        </button>
                      } columns={printCols} listId={storageId} />
                    </div>

                    <div className="flex-shrink-0">
                      <div className="hstack text-nowrap gap-2">
                        {isMultiDeleteButton && (
                          <button
                            className="btn btn-danger"
                            onClick={() => setDeleteModalMulti(true)}
                          >
                            <i className="ri-delete-bin-2-line"></i>
                          </button>
                        )}

                        {/*<button className="btn btn-primary p-1">*/}
                        {/*  Фильтры{" "}*/}
                        {/*  <i className="ri-filter-2-line me-1 align-bottom"></i>*/}
                        {/*</button>*/}
                        {/*<button*/}
                        {/*  className="btn btn-soft-success"*/}
                        {/*  onClick={() => setIsExportCSV(true)}*/}
                        {/*>*/}
                        {/*  Export*/}
                        {/*</button>*/}

                        {/*<UncontrolledDropdown>*/}
                        {/*  <DropdownToggle*/}
                        {/*    href="#"*/}
                        {/*    className="btn btn-soft-info"*/}
                        {/*    tag="button"*/}
                        {/*  >*/}
                        {/*    <i className="ri-more-2-fill"></i>*/}
                        {/*  </DropdownToggle>*/}
                        {/*  <DropdownMenu className="dropdown-menu-end">*/}
                        {/*    <DropdownItem className="dropdown-item" href="#">*/}
                        {/*      All*/}
                        {/*    </DropdownItem>*/}
                        {/*    <DropdownItem className="dropdown-item" href="#">*/}
                        {/*      Last Week*/}
                        {/*    </DropdownItem>*/}
                        {/*    <DropdownItem className="dropdown-item" href="#">*/}
                        {/*      Last Month*/}
                        {/*    </DropdownItem>*/}
                        {/*    <DropdownItem className="dropdown-item" href="#">*/}
                        {/*      Last Year*/}
                        {/*    </DropdownItem>*/}
                        {/*  </DropdownMenu>*/}
                        {/*</UncontrolledDropdown>*/}
                      </div>
                    </div>
                  </div>
                </CardHeader>
              </Card>

              {filtering &&
                  <section className={styles.filter_section}>
                    <h2>Остатки</h2>
                    {storageId &&
                      <div className={styles.filter_element_date}>
                        <Label
                            htmlFor="product-field"
                            className={`form-label ${styles.filter_label}`}
                        >
                          Дата
                        </Label>
                        <div className={`input-group ${styles.date_range}`}>
                          <span className="input-group-text" id="basic-addon1"><i className="ri-calendar-2-line"></i></span>
                          <Flatpickr
                              placeholder="Выберите дату/период"
                              className={`form-control`}
                              options={{
                                mode: "range",
                                dateFormat: "d M, Y",
                                locale: Russian,
                                onChange: function(selectedDates, dateStr, instance) {
                                  // Handle the selected date or date range here
                                  const formattedDates = selectedDates.map(date =>
                                      date.toLocaleDateString(
                                          'ru',
                                          { year: 'numeric', month: '2-digit', day: '2-digit' }
                                      )
                                          .replace('.', '-')
                                          .replace('.', '-')
                                          .split('-')
                                          .reverse()
                                          .join('-')
                                  );

                                  if (formattedDates.length === 2) { // correctly selected date
                                    formattedDates[0] !== formattedDates[1]
                                        ? setFilters({...filters, dates: {range_start: formattedDates[0], range_end: formattedDates[1]}})
                                        : setFilters({...filters, dates: {date: formattedDates[0]}});
                                  }/*else{
                                    let updatedFilters = {...filters};
                                    delete updatedFilters.dates;
                                    setFilters(updatedFilters)
                                  }*/
                                  // console.log(selectedDates); // Array of selected Date objects
                                  // console.log(dateStr); // String representation of the selected date or date range
                                },
                              }}
                          />
                        </div>
                        {/*<Flatpickr*/}
                        {/*    className="form-control bg-light border-light"*/}
                        {/*    id="datepicker-publish-input"*/}
                        {/*    placeholder="Select a date"*/}
                        {/*    options={{*/}
                        {/*      altInput: true,*/}
                        {/*      altFormat: "F j, Y",*/}
                        {/*      mode: "multiple",*/}
                        {/*      dateFormat: "d.m.y",*/}
                        {/*    }}*/}
                        {/*/>*/}
                        {/*<Input*/}
                        {/*    style={{height: '32px'}}*/}
                        {/*    // className={styles.exampleInputdate}*/}
                        {/*    id="exampleInputdate"*/}
                        {/*    type="date"*/}
                        {/*    min="2023-01-01T00:00"*/}
                        {/*    max="2030-01-01T00:00"*/}
                        {/*    required*/}
                        {/*    // value={getCurrentDateTime()}*/}
                        {/*    // onChange={handleDateChange}*/}
                        {/*/>*/}
                      </div>
                    }

                    <div style={{display: "flex", flexDirection: "column"}} className={styles.filter_element}>
                      <Label
                          htmlFor="product-field"
                          className={`form-label ${styles.filter_label}`}
                      >
                        Товар
                      </Label>
                      {/*<Input*/}
                      {/*  type="text"*/}
                      {/*  placeholder="Поиск по товарам"*/}
                      {/*  value={searchTerm}*/}
                      {/*  onChange={handleSearchChange}*/}
                      {/*/>*/}
                      <TagInput
                          tags={searchTerm}
                          onTagsChange={(tags) =>
                              handleProductsChange(tags)
                          }
                      />
                      {/*<ProductDropdown*/}
                      {/*    products={products}*/}
                      {/*    productsLoading={productsLoading}*/}
                      {/*    selectedProducts={selectedProducts}*/}
                      {/*    onProductSelect={setSelectedProducts}*/}
                      {/*/>*/}
                      {/*<SelectedProducts*/}
                      {/*    selectedProducts={selectedProducts}*/}
                      {/*    products={products}*/}
                      {/*    onDelete={handleProductRemove}*/}
                      {/*/>*/}
                    </div>
                    {!storageId &&
                      <div className={styles.filter_element}>
                        <Label
                            htmlFor="product-field"
                            className={`form-label ${styles.filter_label}`}
                        >
                          Склад
                        </Label>
                        <select
                            // style={{height: '32px', padding: '6px'}}
                            value={filters.storage || ""}
                            onChange={(e) => {
                              const selectedStorageId = e.target.value;
                              if (selectedStorageId) {
                                setFilters({...filters, storage: selectedStorageId});
                              }else{
                                let updatedFilters = {...filters};
                                delete updatedFilters.storage;
                                setFilters(updatedFilters);
                              }
                            }}
                            className={`form-select`}
                            aria-label="Default select example"
                        >
                          <option style={{ fontWeight: "bold" }} value="">
                            Склад
                          </option>
                          {storages.map((storage) => (
                              <option key={storage.id} value={storage.id}>
                                {storage.name}
                              </option>
                          ))}
                        </select>
                      </div>
                    }
                  </section>
              }

            </Col>
            <Col xxl={9} style={{ width: "100%"}}>
              <Card id="contactList">
                <CardBody className="pt-0">
                  <div>
                    {
                      loading ? ( // Show loader only when loading
                          <Loader />
                        ) :
                      accounts && columns?.length > 0 ? (
                        filteredProducts.length > 0 ? (
                        <TableContainer
                          columns={columns}
                          data={filteredProducts}
                          // isGlobalFilter={true}
                          // isAddUserList={false}
                          customPageSize={15}

                          serverCount={accounts.length}
                          // serverNext={paginationOptions?.next}
                          // serverPrevious={paginationOptions?.previous}
                          // onPageChange={onPageChange}
                          currentPage={currentPage}
                          setCurrentPage={setCurrentPage}

                          customRow={{colspan: 5, value: totalSelfCost || storage?.total_self_cost || 0}}
                          className="custom-header-css"
                          divClass="table-responsive table-card mb-3"
                          tableClass={`align-middle table-nowrap ${styles.table_with_checkbox}`}
                          theadClass="table-light"
                          handleContactClick={handleContactClicks}
                          SearchPlaceholder="Поиск..."
                        />
                      ) : (
                        <h5
                          style={{ lineHeight: "1.6", color: 'grey' }}
                          className="text-center mt-4"
                        >
                          Остатков не найдено.
                        </h5>
                      )
                    ) : (
                      <Loader />
                    )}
                  </div>

                  <Modal id="showModal" isOpen={modal} toggle={toggle} centered>
                    <ModalHeader className="bg-info-subtle p-3" toggle={toggle}>
                      {!!isEdit ? "Редактировать остаток" : "Добавить остаток"}
                    </ModalHeader>

                    <Form
                      className="tablelist-form"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <ModalBody>
                        <Input type="hidden" id="id-field" />
                        <Row className="g-3">
                          <Col lg={12}>
                            <div>
                              <Label
                                htmlFor="product-field"
                                className="form-label"
                              >
                                Товар
                              </Label>
                              <Select
                                placeholder="Поиск..."
                                name="product"
                                id="product-field"
                                options={options.slice(0, 5)}
                                onChange={(selectedOption) => {
                                  validation.setFieldValue(
                                    "product",
                                    selectedOption?.value
                                  );
                                }}
                                onBlur={validation.handleBlur("product")}
                                value={
                                  options.find(
                                    (option) =>
                                      option.value === validation.values.product
                                  ) || null
                                }
                                isClearable
                                isSearchable
                              />
                              {validation.touched.product &&
                              validation.errors.product ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.product}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col lg={4}>
                            <div>
                              <Label
                                htmlFor="remaining-field"
                                className="form-label"
                              >
                                Остаток
                              </Label>

                              <Input
                                name="remaining"
                                id="remaining-field"
                                // defaultValue="1"
                                // max="50"
                                // min="1"
                                type="number"
                                className="form-control"
                                // placeholder="Введите количество..."
                                validate={{
                                  required: { value: true },
                                }}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.remaining || ""}
                                invalid={
                                  validation.touched.remaining &&
                                  validation.errors.remaining
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.remaining &&
                              validation.errors.remaining ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.remaining}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          {/*<Col lg={12}>*/}
                          {/*  <div>*/}
                          {/*    <Label*/}
                          {/*      htmlFor="taginput-choices"*/}
                          {/*      className="form-label font-size-13 text-muted"*/}
                          {/*    >*/}
                          {/*      Tags*/}
                          {/*    </Label>*/}
                          {/*    <Select*/}
                          {/*      isMulti*/}
                          {/*      value={tag}*/}
                          {/*      onChange={(e) => {*/}
                          {/*        handlestag(e);*/}
                          {/*      }}*/}
                          {/*      className="mb-0"*/}
                          {/*      options={tags}*/}
                          {/*      id="taginput-choices"*/}
                          {/*    ></Select>*/}

                          {/*    {validation.touched.tags &&*/}
                          {/*    validation.errors.tags ? (*/}
                          {/*      <FormFeedback type="invalid">*/}
                          {/*        {validation.errors.tags}*/}
                          {/*      </FormFeedback>*/}
                          {/*    ) : null}*/}
                          {/*  </div>*/}
                          {/*</Col>*/}
                        </Row>
                      </ModalBody>
                      <ModalFooter>
                        <div className="hstack gap-2 justify-content-end">
                          <button
                            type="button"
                            className="btn btn-light"
                            onClick={() => {
                              setModal(false);
                            }}
                          >
                            {" "}
                            Закрыть{" "}
                          </button>
                          <button
                            type="submit"
                            className="btn btn-success"
                            id="add-btn"
                          >
                            {" "}
                            {!!isEdit ? "Сохранить" : "Создать"}{" "}
                          </button>
                        </div>
                      </ModalFooter>
                    </Form>
                  </Modal>
                  {/* task modal */}
                  <Modal
                    isOpen={modalTask}
                    toggle={toggleTask}
                    centered
                    size="lg"
                    className="border-0"
                    modalClassName="modal fade zoomIn"
                  >
                    <ModalHeader
                      className="p-3 bg-info-subtle"
                      toggle={toggleTask}
                    >
                      {!!isEdited ? "Edit Task" : "Create Task"}
                    </ModalHeader>
                    <Form
                      className="tablelist-form"
                      onSubmit={(e) => {
                        e.preventDefault();
                        valid.handleSubmit();
                        return false;
                      }}
                    >
                      <ModalBody className="modal-body">
                        <Row className="g-3">
                          <Col lg={12}>
                            <Label for="taskId-field" className="form-label">
                              Order Id
                            </Label>
                            <Input
                              name="taskId"
                              id="taskId-field"
                              className="form-control"
                              placeholder="Enter Task Id "
                              type="text"
                              validate={{
                                required: { value: true },
                              }}
                              onChange={valid.handleChange}
                              onBlur={valid.handleBlur}
                              value={valid.values.taskId || ""}
                              invalid={
                                valid.touched.taskId && valid.errors.taskId
                                  ? true
                                  : false
                              }
                            />
                            {valid.touched.taskId && valid.errors.taskId ? (
                              <FormFeedback type="invalid">
                                {valid.errors.taskId}
                              </FormFeedback>
                            ) : null}
                          </Col>

                          <Col lg={12}>
                            <Label
                              for="projectName-field"
                              className="form-label"
                            >
                              Project Name
                            </Label>
                            <Input
                              name="project"
                              id="projectName-field"
                              className="form-control"
                              placeholder="Project name"
                              type="text"
                              validate={{
                                required: { value: true },
                              }}
                              onChange={valid.handleChange}
                              onBlur={valid.handleBlur}
                              value={valid.values.project || ""}
                              invalid={
                                valid.touched.project && valid.errors.project
                                  ? true
                                  : false
                              }
                            />
                            {valid.touched.project && valid.errors.project ? (
                              <FormFeedback type="invalid">
                                {valid.errors.project}
                              </FormFeedback>
                            ) : null}
                          </Col>
                          <Col lg={12}>
                            <div>
                              <Label
                                for="tasksTitle-field"
                                className="form-label"
                              >
                                Title
                              </Label>
                              <Input
                                name="task"
                                id="tasksTitle-field"
                                className="form-control"
                                placeholder="Title"
                                type="text"
                                validate={{
                                  required: { value: true },
                                }}
                                onChange={valid.handleChange}
                                onBlur={valid.handleBlur}
                                value={valid.values.task || ""}
                                invalid={
                                  valid.touched.task && valid.errors.task
                                    ? true
                                    : false
                                }
                              />
                              {valid.touched.task && valid.errors.task ? (
                                <FormFeedback type="invalid">
                                  {valid.errors.task}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col lg={12}>
                            <Label
                              for="clientName-field"
                              className="form-label"
                            >
                              Client Name
                            </Label>
                            <Input
                              name="creater"
                              id="clientName-field"
                              className="form-control"
                              placeholder="Client name"
                              type="text"
                              validate={{
                                required: { value: true },
                              }}
                              onChange={valid.handleChange}
                              onBlur={valid.handleBlur}
                              value={valid.values.creater || ""}
                              invalid={
                                valid.touched.creater && valid.errors.creater
                                  ? true
                                  : false
                              }
                            />
                            {valid.touched.creater && valid.errors.creater ? (
                              <FormFeedback type="invalid">
                                {valid.errors.creater}
                              </FormFeedback>
                            ) : null}
                          </Col>

                          <Col lg={6}>
                            <Label for="duedate-field" className="form-label">
                              Due Date
                            </Label>

                            <Flatpickr
                              name="dueDate"
                              id="duedate-field"
                              className="form-control"
                              placeholder="Select a date"
                              options={{
                                altInput: true,
                                altFormat: "d M, Y",
                                dateFormat: "d M, Y",
                              }}
                              onChange={(e) => dateformate(e)}
                              value={valid.values.dueDate || ""}
                            />
                            {valid.touched.dueDate && valid.errors.dueDate ? (
                              <FormFeedback type="invalid">
                                {valid.errors.dueDate}
                              </FormFeedback>
                            ) : null}
                          </Col>
                          <Col lg={6}>
                            <Label for="ticket-status" className="form-label">
                              Status
                            </Label>
                            <Input
                              name="status"
                              type="select"
                              className="form-select"
                              id="ticket-field"
                              onChange={valid.handleChange}
                              onBlur={valid.handleBlur}
                              value={valid.values.status || ""}
                            >
                              <option value="">Status</option>
                              <option value="New">New</option>
                              <option value="Inprogress">Inprogress</option>
                              <option value="Pending">Pending</option>
                              <option value="Completed">Completed</option>
                            </Input>
                            {valid.touched.status && valid.errors.status ? (
                              <FormFeedback type="invalid">
                                {valid.errors.status}
                              </FormFeedback>
                            ) : null}
                          </Col>
                          <Col lg={12}>
                            <Label for="priority-field" className="form-label">
                              Priority
                            </Label>
                            <Input
                              name="priority"
                              type="select"
                              className="form-select"
                              id="priority-field"
                              onChange={valid.handleChange}
                              onBlur={valid.handleBlur}
                              value={valid.values.priority || ""}
                            >
                              <option value="">Priority</option>
                              <option value="High">High</option>
                              <option value="Medium">Medium</option>
                              <option value="Low">Low</option>
                            </Input>
                            {valid.touched.priority && valid.errors.priority ? (
                              <FormFeedback type="invalid">
                                {valid.errors.priority}
                              </FormFeedback>
                            ) : null}
                          </Col>
                        </Row>
                      </ModalBody>
                      <div className="modal-footer">
                        <div className="hstack gap-2 justify-content-end">
                          <Button
                            type="button"
                            onClick={() => {
                              setModalTask(false);
                            }}
                            className="btn-light"
                          >
                            Закрыть
                          </Button>
                          <button
                            type="submit"
                            className="btn btn-success"
                            id="add-btn"
                          >
                            {!!isEdit ? "Update Task" : "Add Task"}
                          </button>
                        </div>
                      </div>
                    </Form>
                  </Modal>
                  <ToastContainer closeButton={false} />
                </CardBody>
              </Card>
            </Col>

            {/* <Col xxl={3}>
              <Card id="contact-view-detail">
                <CardBody className="text-center">
                  <div className="position-relative d-inline-block">
                    <img
                      src={process.env.REACT_APP_API_URL + "/images/users/" + (info.image_src || "avatar-10.jpg")}
                      alt=""
                      className="avatar-lg rounded-circle img-thumbnail"
                    />
                    <span className="contact-active position-absolute rounded-circle bg-success">
                      <span className="visually-hidden"></span>
                    </span>
                  </div>
                  <h5 className="mt-4 mb-1">{info.name || "Tonya Noble"}</h5>
                  <p className="text-muted">{info.company || "Nesta Technologies"}</p>

                  <ul className="list-inline mb-0">
                    <li className="list-inline-item avatar-xs">
                      <Link
                        to="#"
                        className="avatar-title bg-success-subtle text-success fs-15 rounded"
                      >
                        <i className="ri-phone-line"></i>
                      </Link>
                    </li>
                    <li className="list-inline-item avatar-xs">
                      <Link
                        to="#"
                        className="avatar-title bg-danger-subtle text-danger fs-15 rounded"
                      >
                        <i className="ri-mail-line"></i>
                      </Link>
                    </li>
                    <li className="list-inline-item avatar-xs">
                      <Link
                        to="#"
                        className="avatar-title bg-warning-subtle text-warning fs-15 rounded"
                      >
                        <i className="ri-question-answer-line"></i>
                      </Link>
                    </li>
                  </ul>
                </CardBody>
                <CardBody>
                  <h6 className="text-muted text-uppercase fw-semibold mb-3">
                    Personal Information
                  </h6>
                  <p className="text-muted mb-4">
                    Hello, I'm {info.name || "Tonya Noble"}, The most effective objective is one
                    that is tailored to the job you are applying for. It states
                    what kind of career you are seeking, and what skills and
                    experiences.
                  </p>
                  <div className="table-responsive table-card">
                    <Table className="table table-borderless mb-0">
                      <tbody>
                        <tr>
                          <td className="fw-medium">
                            Designation
                          </td>
                          <td>Lead Designer / Developer</td>
                        </tr>
                        <tr>
                          <td className="fw-medium">
                            Email ID
                          </td>
                          <td>{info.email || "tonyanoble@velzon.com"}</td>
                        </tr>
                        <tr>
                          <td className="fw-medium">
                            Phone No
                          </td>
                          <td>{info.phone || "414-453-5725"}</td>
                        </tr>
                        <tr>
                          <td className="fw-medium">
                            Lead Score
                          </td>
                          <td>{info.lead_score || "154"}</td>
                        </tr>
                        <tr>
                          <td className="fw-medium">
                            Tags
                          </td>
                          <td>
                            {(info.tags ||
                              [
                                "Lead",
                                "Partner"
                              ]
                            ).map((item, key) => (<span className="badge bg-primary-subtle text-primary me-1" key={key}>{item}</span>))
                            }
                          </td>
                        </tr>
                        <tr>
                          <td className="fw-medium">
                            Last Contacted
                          </td>
                          <td>
                            {handleValidDate(info.last_contacted || "2021-04-13T18:30:00.000Z")}{" "}
                            <small className="text-muted">{handleValidTime(info.last_contacted || "2021-04-13T18:30:00.000Z")}</small>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col> */}
          </Row>
              ) : (
              <h3 className="text-center my-5" style={{opacity: 0.3}}>У вас нет прав для просмотра данной страницы</h3>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CrmContacts;

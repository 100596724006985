import React from 'react';
import { Link } from 'react-router-dom';
import { Row } from "reactstrap";

const ServerPagination = ({ paginationOptions, onPageChange, customPageSize, currentPage, setCurrentPage, className }) => {
    const { count, next, previous } = paginationOptions;
    const itemsPerPage = Math.min(customPageSize, count - (currentPage - 1) * customPageSize);
    const totalPages = Math.ceil(count / customPageSize);
    // const pageNumbers = Array.from({ length: totalPages }, (_, index) => index + 1);

    const handlePageClick = (pageNumber) => {
        onPageChange({ page: pageNumber });
        setCurrentPage(pageNumber);
    };

    const handlePrevPage = () => {
        if (previous) {
            const prevPage = currentPage - 1;
            onPageChange({ page: prevPage });
            setCurrentPage(prevPage);
        }
    };

    const handleNextPage = () => {
        if (next) {
            const nextPage = currentPage + 1;
            onPageChange({ page: nextPage });
            setCurrentPage(nextPage);
        }
    };

    // Логика для отображения кнопок страниц
    const maxVisiblePages = 4; // Максимальное количество видимых страниц
    const halfVisible = Math.floor(maxVisiblePages / 2);
    const startPage = Math.max(1, currentPage - halfVisible);
    const endPage = Math.min(totalPages, currentPage + halfVisible);

    const pageButtons = [];

    if (startPage > 1) {
        pageButtons.push(
            <li className="page-item" key="page1">
                <Link
                    to="#"
                    className="page-link"
                    onClick={() => handlePageClick(1)}
                >
                    1
                </Link>
            </li>
        );

        if (startPage > 2) {
            pageButtons.push(
                <li className="page-item disabled" key="startEllipsis">
                    <span className="page-link">...</span>
                </li>
            );
        }
    }

    for (let i = startPage; i <= endPage; i++) {
        pageButtons.push(
            <li key={i} className="page-item">
                <Link
                    to="#"
                    className={currentPage === i ? "page-link active" : "page-link"}
                    onClick={() => handlePageClick(i)}
                >
                    {i}
                </Link>
            </li>
        );
    }

    if (endPage < totalPages) {
        if (endPage < totalPages - 1) {
            pageButtons.push(
                <li className="page-item disabled" key="endEllipsis">
                    <span className="page-link">...</span>
                </li>
            );
        }

        pageButtons.push(
            <li className="page-item" key={totalPages}>
                <Link
                    to="#"
                    className="page-link"
                    onClick={() => handlePageClick(totalPages)}
                >
                    {totalPages}
                </Link>
            </li>
        );
    }

    return (
        <React.Fragment>
            <Row className="g-0 justify-content-between mt-4">
                <div className="col-sm-6">
                    <div className="text-muted">
                        Показано
                        <span className="fw-semibold ms-1">{itemsPerPage} </span>
                        из
                        <span className="fw-semibold"> {count} </span>
                        Результатов
                    </div>
                </div>
                <div className="col-sm-auto">
                    <ul className={className}>
                        <li className={currentPage <= 1 ? "page-item disabled" : "page-item"}>
                            <Link to="#" className="page-link" onClick={handlePrevPage} aria-disabled={currentPage <= 1}>
                                Назад
                            </Link>
                        </li>
                        {pageButtons}
                        <li className={currentPage >= totalPages ? "page-item disabled" : "page-item"}>
                            <Link to="#" className="page-link" onClick={handleNextPage} aria-disabled={currentPage >= totalPages}>
                                Вперед
                            </Link>
                        </li>
                    </ul>
                </div>
            </Row>
        </React.Fragment>
    );
};

export default ServerPagination;


import React, { useState, useCallback, useEffect } from "react";
import CreateOrder from "./CreateOrder";
import {Link, useLocation, useParams} from "react-router-dom";
import {Card, CardBody, Col, Container, Label, Row, Input} from "reactstrap";
import { toast, ToastContainer } from "react-toastify";
import {
  deleteOrderApi,
  getStoragesApi,
  getOrdersDataApi,
  getStatusesApi,
  getSalesChannelsApi, getAccountsApi, getProductsApi
} from "../../helpers/backend_helper";
import {
  convertToReadableDateTime,
} from "../../helpers/utils";
import styles from "../Invoices/InvoiceCreate.module.css";
import DeleteModal from "../../Components/Common/DeleteModal";
import Flatpickr from "react-flatpickr";
import { Russian } from "flatpickr/dist/l10n/ru.js";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import Loader from "../../Components/Common/Loader";
import TagInput from "../../Components/Common/TagInput";

import ProductDropdown from "../Crm/Helpers/ProductDropdown";
import SelectedProducts from "../Crm/Helpers/SelectedProducts";
import ServerPaginationBest from "../../Components/Common/ServerPaginationBest";
import ServerPagination from "../../Components/Common/ServerPagination";



const Order = ({state, isReturns}) => {
  const [loading, setLoading] = useState(true);
  const [orders, setOrders] = React.useState([]);
  const location = useLocation();
  const [paginationOptions, setPaginationOptions] = useState({});
  const [storages, setStorages] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [salesChannels, setSalesChannels] = useState([]);
  const [accounts, setAccounts] = useState({});
  const [delayTimer, setDelayTimer] = useState(null);
  const [filtering, setFiltering] = useState(false);
  const [filters, setFilters] = useState({});

  const [currentPage, setCurrentPage] = useState(1); // Initial page
  // const perPageData = 16;
  // const perPageData = 10;

  const [products, setProducts] = useState([]);
  const [productsLoading, setProductsLoading] = useState(true);
  const [searchCustomerTags, setSearchCustomerTags] = useState([]);
  const [searchCodeTags, setSearchCodeTags] = useState([]);

  useEffect(() => {
    fetchOrdersData();
  }, [filters, state, isReturns, searchCustomerTags, searchCodeTags]);

  const hasPermissions = (permissions) => {
    return accounts.results && accounts.current && permissions.includes(accounts.results[accounts.current].access)
  };
  const getAccessObjects = () => {
    return String(accounts?.results && accounts.current && accounts?.results[accounts.current]?.access_objects);
  };

  useEffect(() => {
    const fetchAccounts = () => {
      getAccountsApi()
      .then((data) => {
        setAccounts(data);
      })
      .catch(() => {});
    };
  const fetchProducts = () => {
    getProductsApi({ all: true })
      .then((response) => {
        setProducts(response);
      })
      .catch((error) => {})
      .finally(() => {
        setProductsLoading(false);
      });
    };
    if (!Object.keys(accounts).length) {
      fetchAccounts();
    }
    if (!products.length && !loading) {
      fetchProducts();
    }
  }, [loading]);

  /*const fetchOrdersData = () => {
    setLoading(true);
    let data = {};
    if ('dates' in filters) {
      Object.keys(filters['dates']).forEach(key => {
        data[key] = filters['dates'][key];
      });
    }
    if ('storage' in filters) {
      data.storage = filters.storage;
    }
    if ('status' in filters) {
      data.status = filters.status.id;
    }
    if ('sales_channel' in filters) {
      data.sales_channel = filters.sales_channel;
    }
    if (state) {
      data.state = state;
    }
    if (isReturns) {
      data.is_returns = isReturns;
    }
    getOrdersDataApi(data)
      .then((data) => {
        // setOrders(data);
        setOrders(data.results);
        setPaginationOptions({
          count: data.count,
          previous: data.previous,
          next: data.next
        });
      })
      .catch(() => {
        toast.error("Не удалось получить данные заказов");
      })
      .finally(() => {
        setLoading(false);
      });
  };*/
  const fetchOrdersData = (page = 1) => {
    setLoading(true);
    let data = {
      page, // добавляем номер страницы
    };

    if ('dates' in filters) {
      Object.keys(filters['dates']).forEach(key => {
        data[key] = filters['dates'][key];
      });
    }
    if ('storage' in filters) {
      data.storage = filters.storage;
    }
    if ('status' in filters) {
      data.status = filters.status.id;
    }
    if ('sales_channel' in filters) {
      data.sales_channel = filters.sales_channel;
    }
    if (state) {
      data.state = state;
    }
    if (isReturns) {
      data.is_returns = isReturns;
    }
    if (searchCustomerTags.length > 0) {
      data.search = searchCustomerTags;
    }
    if (searchCodeTags.length > 0) {
      data.search_code = searchCodeTags;
    }

    getOrdersDataApi(data)
        .then((data) => {
          setOrders(data.results);
          setPaginationOptions({
            count: data.count,
            previous: data.previous,
            next: data.next
          });
        })
        .catch(() => {
          toast.error("Не удалось получить данные заказов");
        })
        .finally(() => {
          setLoading(false);
        });
  };

  const toggleFiltering = () => {
    if (filtering) {
      setFiltering(false);
    } else {
      setFiltering(true);
    }
  };

  // const fetchOrdersData = () => {
  //   setLoading(true);
  //   getOrdersDataApi()
  //     .then((data) => {
  //       setOrders(data);
  //     })
  //     .catch(() => {
  //       toast.error("Не удалось получить данные о заказах");
  //     })
  //     .finally(() => {
  //       setLoading(false);
  //     });
  // };

  React.useEffect(() => {
    const message = location.state && location.state.message;
    if (message) {
      toast.success(message);
    }
    if (!storages.length && !loading) {
      getStoragesApi()
          .then((response) => {
            setStorages(response);
          })
          .catch((error) => {});
    }

    if (!statuses.length && !loading) {
      getStatusesApi({is_returns: isReturns})
          .then((response) => {
            setStatuses(response);
          })
          .catch((error) => {});
    }

    if (!salesChannels.length && !loading) {
      getSalesChannelsApi()
          .then((response) => {
            setSalesChannels(response);
          })
          .catch((error) => {});
    }

  }, [isReturns, loading]);

  const handleDelete = (itemId) => {
    // axios
    //     .delete(`/core/reception/${itemId}`)
    deleteOrderApi({ id: itemId })
      .then((response) => {
        setOrders(orders.filter((item) => item.id !== itemId));
        toast.success("Заказ успешно удален", { position: "top-right" });
      })
      .catch((error) => {
        toast.error("Не удалось удалить елемент заказа", {
          position: "top-right",
        });
      });
  };

  const [selectedCheckBoxDelete, setSelectedCheckBoxDelete] = useState([]);
  const [isMultiDeleteButton, setIsMultiDeleteButton] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteModalMulti, setDeleteModalMulti] = useState(false);

  const deleteMultiple = async () => {
    const checkall = document.getElementById("checkBoxAll");

    for (const element of selectedCheckBoxDelete) {
      await deleteOrderApi({ id: element.value })
        .then(() => {
          // setOrders((prevOrder) =>
          //     prevOrder.filter((item) => String(item.id) !== element.value)
          // );
        })
        .catch((error) => {
          toast.error("Не удалось удалить заказ", { position: "top-right" });
        });
      setTimeout(() => {
        toast.clearWaitingQueue();
      }, 3000);
    }
    setDeleteModal(false);
    fetchOrdersData();
    toast.success("Выбранные заказы были успешно удалены", {
      position: "top-right",
    });
    setIsMultiDeleteButton(false);
    checkall.checked = false;
    checkedAll();
  };

  const checkedAll = useCallback(() => {
    const checkall = document.getElementById("checkBoxAll");
    const ele = document.querySelectorAll(".contactCheckBox");

    if (checkall.checked) {
      ele.forEach((ele) => {
        ele.checked = true;
      });
    } else {
      ele.forEach((ele) => {
        ele.checked = false;
      });
    }
    deleteCheckbox();
  }, []);

  const deleteCheckbox = () => {
    const ele = document.querySelectorAll(".contactCheckBox:checked");
    ele.length > 0
      ? setIsMultiDeleteButton(true)
      : setIsMultiDeleteButton(false);
    setSelectedCheckBoxDelete(ele);
  };

  ////////////////////поиск///////////////////////////////////

  // const [searchProductsTerm, setSearchProductsTerm] = useState("");
  // const [searchCustomerTerm, setSearchCustomerTerm] = useState("");
  const [searchProducts, setSearchProducts] = useState("");

  /*useEffect(() => {
    if (searchCustomerTags) {
      if (delayTimer) {
        clearTimeout(delayTimer);
      }
      const timer = setTimeout(() => {
        // const updatedParams = { ...fetchParams, search: searchTerm, page: 1 }; // Reset page to 1
        // setFetchParams(updatedParams);
        // fetchProducts(updatedParams);
        fetchOrdersData(1, searchCustomerTags);
      }, 700); // Adjust delay time as needed
      setDelayTimer(timer);
    }
  }, [searchCustomerTags]);*/

  // const handleProductsChange = (event) => {
  //   setSearchProductsTerm(event.target.value);
  // };
  const handleProductsChange = (tags) => {
    setSearchProducts(tags);
  };
  // const handleCustomerChange = (event) => {
  //   setSearchCustomerTerm(event.target.value);
  // };
  const handleCustomerTagsChange = (newTags) => {
    setSearchCustomerTags(newTags);
  };
  const handleCodeTagsChange = (newTags) => {
    setSearchCodeTags(newTags);
  };

  const [selectedProducts, setSelectedProducts] = useState([]);

  const handleProductRemove = (productId) => {
    setSelectedProducts(prevSelected =>
        prevSelected.filter(id => id !== productId)
    );
  };

  /*const filteredOrders = orders.filter((order) => {
    const productsMatch = searchProducts.length === 0 || searchProducts.some((searchTerm) =>
        order.order_products.some((product) =>
            product.product_data.title.toLowerCase().includes(searchTerm.toLowerCase())
        )
    );
    const customerMatch = searchCustomerTags.length === 0 ||
        searchCustomerTags.some(tag =>
            order.customer.toLowerCase().includes(tag.toLowerCase()) ||
            order.phone.toLowerCase().includes(tag.toLowerCase())
        );

    return productsMatch && customerMatch;
  });*/

  const filteredOrders = orders.filter((order) => {
    const productsMatch = selectedProducts.length === 0 || order.order_products.some((product) =>
        selectedProducts.includes(product.product_data.id) // Ensure this matches with selectedProducts IDs
    );
    // const customerMatch = searchCustomerTags.length === 0 ||
    //     searchCustomerTags.some(tag =>
    //         order.customer.toLowerCase().includes(tag.toLowerCase()) ||
    //         order.phone.toLowerCase().includes(tag.toLowerCase())
    //     );
    // return productsMatch && customerMatch;
    return productsMatch;
  });

  // Calculate the start and end index for the current page
  // const startIndex = (currentPage - 1) * perPageData;
  // const endIndex = startIndex + perPageData;

  // Get the slice of orders to display on the current page
  // const ordersToShow = filteredOrders.slice(startIndex, endIndex);

  //////////////////////////////////////////////////

  document.title = "CRMBEK : Заказы";

  return (
    <React.Fragment>
      <div className="page-content">
        <DeleteModal
          show={deleteModalMulti}
          onDeleteClick={() => {
            deleteMultiple();
            setDeleteModalMulti(false);
          }}
          onCloseClick={() => setDeleteModalMulti(false)}
        />

        <Container fluid className="pb-5"> {/*px-5*/}
          <BreadCrumb title={isReturns ? 'Возвраты и Отмены': 'Все заказы'} pageTitle='Заказы' />
          {/*<h4>Все заказы</h4>*/}
          <Row>
            <Col lg={12}>
              {hasPermissions(['owner']) ||
              getAccessObjects().includes('orders') ? (
              <Card>
                <CardBody>
                  <div className="listjs-table" id="customerList">
                    <Row className="g-4 mb-3 justify-content-between">
                      <Col className="flex-grow-1 d-flex align-items-center">
                        {/*{hasPermissions(["owner", "admin"]) && */}
                            <CreateOrder isReturns={isReturns}/>
                        {/*}*/}
                        <button
                            className={`btn ${filtering ? 'btn-primary' : 'btn-outline-primary'} px-2 fs-14 ${styles.filter_button}`}
                            onClick={toggleFiltering}
                        >
                          Фильтры{" "}
                          <i className="ri-filter-2-line me-1 align-bottom"></i>
                        </button>
                      </Col>
                      <Col className="col-sm-auto">
                        {isMultiDeleteButton && hasPermissions(['admin', 'owner']) && (
                            <Col
                                className="col-sm-auto"
                                style={{ marginRight: "10px" }}
                            >
                              <button
                                  style={{ padding: "6px 15px" }}
                                  className="btn btn-danger"
                                  onClick={() => setDeleteModalMulti(true)}
                              >
                                <i className="ri-delete-bin-2-line"></i>
                              </button>
                            </Col>
                        )}
                      </Col>
                    </Row>

                    {filtering &&
                        <section className={styles.filter_section}>
                          <h2>Все заказы</h2>
                          <div className="d-flex flex-wrap">
                            <div className={styles.filter_element_date}>
                              <Label
                                  htmlFor="product-field"
                                  className={`form-label ${styles.filter_label}`}
                              >
                                Дата
                              </Label>
                              <div className={`input-group ${styles.date_range}`}>
                                <span className="input-group-text" id="basic-addon1"><i className="ri-calendar-2-line"></i></span>
                                <Flatpickr
                                    placeholder="Выберите дату/период"
                                    className={`form-control`}
                                    options={{
                                      mode: "range",
                                      dateFormat: "d M, Y",
                                      locale: Russian,
                                      onChange: function(selectedDates, dateStr, instance) {
                                        // Handle the selected date or date range here
                                        const formattedDates = selectedDates.map(date =>
                                            date.toLocaleDateString(
                                                'ru',
                                                { year: 'numeric', month: '2-digit', day: '2-digit' }
                                            )
                                                .replace('.', '-')
                                                .replace('.', '-')
                                                .split('-')
                                                .reverse()
                                                .join('-')
                                        );
                                        if (formattedDates.length === 2) { // correctly selected date
                                          formattedDates[0] !== formattedDates[1]
                                              ? setFilters({...filters, dates: {range_start: formattedDates[0], range_end: formattedDates[1]}})
                                              : setFilters({...filters, dates: {date: formattedDates[0]}});
                                        }/*else{
                                          let updatedFilters = {...filters};
                                          delete updatedFilters.dates;
                                          setFilters(updatedFilters)
                                        }*/
                                        // console.log(selectedDates); // Array of selected Date objects
                                        // console.log(dateStr); // String representation of the selected date or date range
                                      },
                                    }}
                                />
                              </div>
                            </div>
                            {/*<div className={styles.filter_element}>
                              <Label
                                  htmlFor="product-field"
                                  className={`form-label ${styles.filter_label}`}
                              >
                                Товары
                              </Label>
                              <Input
                                  type="text"
                                  placeholder="Поиск по товарам"
                                  value={searchProductsTerm}
                                  onChange={handleProductsChange}
                              />
                            </div>*/}
                            <div className={styles.filter_element} style={{width: 'unset', maxWidth: '100%'}}>
                              <Label
                                  // htmlFor="product-field"
                                  className={`form-label ${styles.filter_label}`}
                              >
                                Товары
                              </Label>
                              {/*<TagInput
                                  tags={searchProducts}
                                  onTagsChange={(tags) =>
                                      handleProductsChange(tags)
                                  }
                              />*/}
                              <ProductDropdown
                                  products={products}
                                  productsLoading={productsLoading}
                                  selectedProducts={selectedProducts}
                                  onProductSelect={setSelectedProducts}
                              />
                              <SelectedProducts
                                  selectedProducts={selectedProducts}
                                  products={products}
                                  onDelete={handleProductRemove}
                              />

                              {/*{param?.values_error && (*/}
                              {/*    <div*/}
                              {/*        style={{*/}
                              {/*          color: "#ed5e5e",*/}
                              {/*          fontSize: "0.875em",*/}
                              {/*        }}*/}
                              {/*    >*/}
                              {/*      {param.values_error}*/}
                              {/*    </div>*/}
                              {/*)}*/}
                            </div>
                            <div className={styles.filter_element}>
                              <Label
                                  // htmlFor="product-field"
                                  className={`form-label ${styles.filter_label}`}
                              >
                                Склад
                              </Label>
                              <select
                                  // style={{height: '32px', padding: '6px'}}
                                  value={filters.storage || ""}
                                  onChange={(e) => {
                                    const selectedStorageId = e.target.value;
                                    if (selectedStorageId) {
                                      setFilters({...filters, storage: selectedStorageId});
                                    }else{
                                      let updatedFilters = {...filters};
                                      delete updatedFilters.storage;
                                      setFilters(updatedFilters);
                                    }
                                  }}
                                  className={`form-select`}
                                  aria-label="Default select example"
                              >
                                <option style={{ fontWeight: "bold" }} value="">
                                  Склад
                                </option>
                                {storages.map((storage) => (
                                    <option key={storage.id} value={storage.id}>
                                      {storage?.full_name}
                                    </option>
                                ))}
                              </select>
                            </div>
                            <div className={styles.filter_element}>
                              <Label
                                  // htmlFor="product-field"
                                  className={`form-label ${styles.filter_label}`}
                              >
                                Номер заказа
                              </Label>
                              <TagInput
                                  // tags={param.values}
                                  tags={searchCodeTags}
                                  onTagsChange={(tags) =>
                                      handleCodeTagsChange(tags)
                                  }
                              />
                            </div>
                            {/*<div className={styles.filter_element}>
                              <Label
                                  htmlFor="product-field"
                                  className={`form-label ${styles.filter_label}`}
                              >
                                Клиент
                              </Label>
                              <Input
                                  type="text"
                                  placeholder="Поиск по клиенту"
                                  value={searchCustomerTerm}
                                  onChange={handleCustomerChange}
                              />
                            </div>*/}
                            <div className={styles.filter_element}>
                              <Label
                                  // htmlFor="product-field"
                                  className={`form-label ${styles.filter_label}`}
                              >
                                Клиент
                              </Label>
                              <TagInput
                                  // tags={param.values}
                                  tags={searchCustomerTags}
                                  onTagsChange={(tags) =>
                                      handleCustomerTagsChange(tags)
                                  }
                              />
                              {/*{param?.values_error && (*/}
                              {/*    <div*/}
                              {/*        style={{*/}
                              {/*          color: "#ed5e5e",*/}
                              {/*          fontSize: "0.875em",*/}
                              {/*        }}*/}
                              {/*    >*/}
                              {/*      {param.values_error}*/}
                              {/*    </div>*/}
                              {/*)}*/}
                            </div>
                            <div className={styles.filter_element}>
                              <Label
                                  htmlFor="product-field"
                                  className={`form-label ${styles.filter_label}`}
                              >
                                Статус
                              </Label>
                              <select
                                  style={filters?.status && {
                                    backgroundColor:
                                        filters?.status?.mark === "positive" ? "rgb(195 255 224)"
                                            : filters?.status?.mark === "refusal" ? "rgb(255 213 213)"
                                                : filters?.status?.mark === "neutral" ? "lightgoldenrodyellow"
                                                    : "rgba(47, 218, 255, 0.28)",
                                    boxShadow:
                                        filters?.status?.mark === "positive" ? "0 0 0 0.25rem rgb(216 253 235)"
                                            : filters?.status?.mark === "refusal" ? "0 0 0 0.25rem rgb(251 227 227)"
                                                : filters?.status?.mark === "neutral" ? "0 0 0 0.25rem #f9f9de"
                                                    : "0 0 0 0.25rem rgb(117 226 249 / 28%)",
                                    border:
                                        filters?.status?.mark === "positive" ? "1px solid rgb(119 247 142)"
                                            : filters?.status?.mark === "refusal" ? "1px solid rgb(255 185 185)"
                                                : filters?.status?.mark === "neutral" ? "1px solid rgb(243 239 90)"
                                                    : "1px solid rgb(119 234 247)",
                                  }}
                                  value={filters?.status?.id || ""}
                                  onChange={(e) => {
                                    const selectedId = e.target.value;
                                    if (selectedId) {
                                      const selectedOption = e.target.options[e.target.selectedIndex];
                                      const statusMark = selectedOption.dataset.mark;
                                      setFilters({...filters, status: {id: selectedId, mark: statusMark}});
                                    }else{
                                      let updatedFilters = {...filters};
                                      delete updatedFilters.status;
                                      setFilters(updatedFilters);
                                    }
                                  }}
                                  className={`form-select ${styles.status_select}`}
                                  aria-label="Default select example"
                              >
                                <option style={{ fontWeight: "bold" }} value="">
                                  Статус
                                </option>
                                <option value="new">
                                  Новый
                                </option>
                                {statuses.map((status) => (
                                    <option key={status.id} value={status.id} data-mark={status.mark}>
                                      {status.name}
                                    </option>
                                ))}
                              </select>
                            </div>
                            <div className={styles.filter_element}>
                              <Label
                                  htmlFor="product-field"
                                  className={`form-label ${styles.filter_label}`}
                              >
                                Канал продаж
                              </Label>
                              <select
                                  value={filters?.sales_channel || ""}
                                  onChange={(e) => {
                                    const selectedId = e.target.value;
                                    if (selectedId) {
                                      setFilters({...filters, sales_channel: selectedId});
                                    }else{
                                      let updatedFilters = {...filters};
                                      delete updatedFilters.sales_channel;
                                      setFilters(updatedFilters);
                                    }
                                  }}
                                  className={`form-select`}
                                  aria-label="Default select example"
                              >
                                <option style={{ fontWeight: "bold" }} value="">
                                  Канал продаж
                                </option>
                                {salesChannels.map((sales_channel) => (
                                    <option key={sales_channel.id} value={sales_channel.id}>
                                      {sales_channel.name}
                                    </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </section>
                    }

                    <div className="table-responsive table-card mt-3 mb-1" style={{overflowX: `${loading ? 'unset' : 'auto'}`}}>
                      {
                        loading ? ( // Show loader only when loading
                          <Loader />
                        ) :
                        filteredOrders && filteredOrders.length > 0 ? (
                        <table
                          className="table align-middle table-nowrap table-hover"
                          id="customerTable"
                          style={{ marginBottom: "0.7rem" }}
                        >
                          <thead className="table-light">
                            <tr>
                              <th className={styles.checkbox}>
                                <input
                                  type="checkbox"
                                  id="checkBoxAll"
                                  className="form-check-input"
                                  onClick={() => checkedAll()}
                                />
                              </th>
                              {/*<th>*/}
                              {/*  Название*/}
                              {/*</th>*/}
                              <th>Дата</th>
                              <th>Клиент</th>
                              <th>Товары</th>
                              <th>Кол-во</th>
                              <th>Склад</th>
                              <th>Сумма</th>
                              <th>Статус</th>
                              <th>Комментарий</th>
                              <th>№ заказа</th>
                              {/*<th></th>*/}
                            </tr>
                          </thead>
                          <tbody>
                            {filteredOrders.map((item, id) => (
                                <tr key={id}>
                                  <td>
                                    <input
                                        type="checkbox"
                                        className="contactCheckBox form-check-input"
                                        value={item.id}
                                        onChange={() => deleteCheckbox()}
                                    />
                                  </td>
                                  <td>
                                    <Link
                                        to={isReturns ? `/returns/${item.id}`: `/orders/${item.id}`}
                                        style={{color: "black"}}
                                    >
                                      {item.ordered_date ? (
                                          <div style={{lineHeight: "1.3"}}>
                                            <div>
                                              {
                                                convertToReadableDateTime(
                                                    item.ordered_date
                                                ).split(" ")[0]
                                              }
                                            </div>
                                            <div>
                                              {
                                                convertToReadableDateTime(
                                                    item.ordered_date
                                                ).split(" ")[1]
                                              }
                                            </div>
                                          </div>
                                      ) : (
                                          "-"
                                      )}
                                    </Link>
                                  </td>
                                  <td
                                      style={{
                                        lineHeight: "1.3",
                                        wordWrap: "break-word",
                                        whiteSpace: "normal",
                                      }}
                                  >
                                    <Link
                                        to={isReturns ? `/returns/${item.id}`: `/orders/${item.id}`}
                                    >
                                      {item.customer ? (
                                          item.phone ? (
                                              <span>
                                          {item.customer}
                                                <br/>
                                                {item.phone}
                                        </span>
                                          ) : (
                                              item.customer
                                          )
                                      ) : (
                                          "не выбрано"
                                      )}
                                    </Link>
                                  </td>
                                  <td style={{position: "relative"}}>
                                    {item?.order_products &&
                                    item.order_products.length > 0 ? (
                                        item.order_products
                                            .slice(0, 2)
                                            .map((order_product, index) => (
                                                <div className={styles.order_products} key={index}>
                                                  {(order_product?.product_data?.image ||
                                                      order_product?.product_data?.url_image) && (
                                                      <div
                                                          style={{
                                                            // height: "30px",
                                                            // width: "40px",
                                                            height: "38px",
                                                            width: "46px",
                                                            minWidth: "46px",
                                                            minHeight: "38px",
                                                            marginRight: "15px",
                                                            verticalAlign: "middle",
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                          }}
                                                      >
                                                        <img
                                                            style={{
                                                              width: "auto",
                                                              maxWidth: "100%",
                                                              maxHeight: "100%",
                                                            }}
                                                            src={
                                                              order_product.product_data.image ||
                                                                order_product?.product_data?.url_image
                                                            }
                                                            alt=" "
                                                        />
                                                      </div>
                                                  )}
                                                  {/*<div className={styles.circle} style={{marginRight: "6px"}}>*/}
                                                  {/*  <span>{order_product?.quantity || 1}</span>*/}
                                                  {/*</div>*/}
                                                  {/*<Link*/}
                                                  {/*    to={isReturns ? `/returns/${item.id}`: `/orders/${item.id}`}*/}
                                                  {/*    style={{color: "black"}}*/}
                                                  {/*>*/}
                                            <span
                                                style={{
                                                  wordWrap: "break-word",
                                                  whiteSpace: "normal",
                                                  lineHeight: 1,
                                                }}
                                            >
                                              {order_product?.product_data
                                                  ?.title || ""}
                                            </span>
                                                  {/*</Link>*/}
                                                </div>
                                            ))
                                    ) : (
                                        <Link
                                            to={isReturns ? `/returns/${item.id}`: `/orders/${item.id}`}
                                            style={{color: "black"}}
                                        >
                                      <span style={{marginLeft: "5px"}}>
                                        не выбрано
                                      </span>
                                        </Link>
                                    )}

                                    {item?.order_products &&
                                        item.order_products.length > 2 && (
                                            <div className={styles.circle_small}>
                                              <div
                                                  className="lh-1"
                                                  style={{
                                                    fontSize: '11px',
                                                    // fontWeight: 'bold',
                                                    // marginLeft: "2px",
                                                    marginLeft: "3px",
                                                    marginTop: "3px",
                                                  }}
                                              >
                                                +{item.order_products.length - 2}
                                              </div>
                                            </div>
                                        )}
                                  </td>

                                  <td style={{position: "relative"}}>
                                    {item?.order_products &&
                                    item.order_products.length > 0 ? (
                                        item.order_products
                                            .slice(0, 2)
                                            .map((order_product, index) => (
                                                <div className={styles.order_products} key={index}>
                                                  <div
                                                      className={styles.circle}
                                                      style={{marginRight: "6px"}}
                                                  >
                                            <span>
                                              {order_product?.quantity || 1}
                                            </span>
                                                  </div>
                                                </div>
                                            ))
                                    ) : (
                                        <span style={{marginLeft: "5px"}}>-</span>
                                    )}
                                    {/*{item?.order_products && item.order_products.length > 2 &&*/}
                                    {/*    <div className={styles.circle_small}>*/}
                                    {/*      <div className="lh-1" style={{marginLeft: '5px', marginTop: '3px'}}>+{item.order_products.length - 2}</div>*/}
                                    {/*    </div>*/}
                                    {/*}*/}
                                  </td>
                                  <td>
                                    {String(
                                        item.storage_obj
                                            ? item.storage_obj?.full_name
                                            : "нет"
                                    )}
                                  </td>
                                  <td>
                                    {String(
                                        item.final_price ? item.final_price : 0
                                    ) + " ₸"}
                                  </td>

                                  {/*{item.draft ? (*/}
                                  {/*    <>*/}
                                  <td>
                                    <div
                                        // style={{
                                        //   backgroundColor: 'rgb(255 252 176)',
                                        //   padding: '6px 12px',
                                        //   color: "#7a7a7a"
                                        // }}
                                        style={{
                                          borderRadius: 0,
                                          padding: "8px 10px",
                                        }}
                                        className={`badge bg-${
                                            item?.status_obj?.mark === "positive"
                                                ? "success"
                                                : item?.status_obj?.mark === "refusal"
                                                    ? "danger"
                                                    : item?.status_obj?.mark === "neutral"
                                                        ? "warning"
                                                        : "info"
                                        }-subtle text-${
                                            item?.status_obj?.mark === "positive"
                                                ? "success"
                                                : item?.status_obj?.mark === "refusal"
                                                    ? "danger"
                                                    : item?.status_obj?.mark === "neutral"
                                                        ? "warning"
                                                        : "info"
                                        } fs-12`}
                                    >
                                      {String(
                                          item.status_obj
                                              ? item.status_obj.name
                                              : "Новый"
                                      )}
                                    </div>
                                  </td>
                                  <td
                                      style={{
                                        maxWidth: "200px",
                                        wordWrap: "break-word",
                                        whiteSpace: "normal",
                                        lineHeight: 1,
                                      }}
                                  >
                                    {String(
                                        item.comment
                                            ? item.comment.length > 100
                                                ? item.comment.slice(0, 50) + "..."
                                                : item.comment
                                            : "-"
                                    )}
                                  </td>
                                  <td>{String(item.code ? item.code : "-")}</td>
                                        {/*<td>
                                          <div className="hstack gap-3">
                                            <Link
                                                to={`/orders/${item.id}`}
                                                className="link-success fs-18"
                                            >
                                              <i className="ri-edit-2-line"></i>
                                            </Link>
                                            <Link
                                                to="#"
                                                className="link-danger fs-15"
                                            >
                                      <span
                                          className="badge bg-danger"
                                          onClick={() => handleDelete(item.id)}
                                      >
                                        Удалить
                                      </span>
                                            </Link>
                                          </div>
                                        </td>*/}
                                      {/*</>
                                  ) : (
                                      <>
                                        <td>
                                    <span className="badge bg-success-subtle text-success">
                                      Загружено
                                    </span>
                                        </td>
                                        <td></td>
                                      </>
                                  )}*/}
                                </tr>
                            ))}
                          </tbody>
                        </table>
                        ) : (
                            <h5
                                style={{lineHeight: "1.6", color: "grey"}}
                                className="text-center mt-3"
                            >
                          Заказов не найдено.
                        </h5>
                      )}
                    </div>

                    {
                      loading ? null :
                      filteredOrders && filteredOrders.length > 0 ? (
                        // <Pagination  # TODO: client pagination
                        //     data={filteredOrders}
                        //     currentPage={currentPage}
                        //     setCurrentPage={setCurrentPage}
                        //     perPageData={perPageData}
                        //     className="pagination"  /* pagination-separated */
                        //     numResults={ordersToShow.length}
                        // />
                        <ServerPagination
                            paginationOptions={paginationOptions}
                            onPageChange={({ page }) => fetchOrdersData(page)} // передаем номер страницы
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            customPageSize={10}
                            className="pagination pagination-separated"
                        />
                    ) : null}

                  </div>
                </CardBody>
                <ToastContainer closeButton={false} />
              </Card>
              ) : (
                  <h3 className="text-center my-5" style={{opacity: 0.3}}>У вас нет прав для просмотра данной страницы</h3>
              )}
            </Col>
          </Row>


        </Container>
      </div>
    </React.Fragment>
  );
};

export default Order;

import React, { useEffect, useState, useRef } from "react";
import {
  CardBody,
  Row,
  Col,
  Card,
  Container,
  Form,
  Input,
  Label,
  Button,
  Table,
  ModalFooter,
  FormFeedback,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";

// import Dropzone from "react-dropzone";
import { Link, useNavigate, useParams } from "react-router-dom";

import styles from "../Invoices/InvoiceCreate.module.css";
import orderStyles from "./Orders.module.css";

//formik
import { useFormik } from "formik";
import * as Yup from "yup";

//redux
import { useDispatch } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import {
  createProductApi,
  getProductsApi,
  getOrderDetailApi,
  getStoragesApi,
  getStatusesApi,
  addStatusApi,
  updateStatusApi,
  deleteStatusApi,
  updateOrderApi,
  addCustomerApi,
  getCustomersApi,
  getSalesChannelsApi,
  getAccountsApi,
} from "../../helpers/backend_helper";
import getCurrentDateTime from "../../slices/invoice/utils";
import { padNumberWithZeros } from "../../helpers/utils";
import clipboardCopy from "clipboard-copy";
import Loader from "../../Components/Common/Loader";
import PrintButton from "../Prints/PrintButton";

const DetailsOrder = ({isReturns}) => {
  const { orderId } = useParams();
  const [productsLoading, setProductsLoading] = useState(true);

  const dispatch = useDispatch();
  const history = useNavigate();

  const [ispaymentDetails, setispaymentDetails] = useState(null);
  const [isCurrency, setisCurrency] = useState("₸");

  function handleispaymentDetails(ispaymentDetails) {
    setispaymentDetails(ispaymentDetails);
  }

  function handleisCurrency(isCurrency) {
    setisCurrency(isCurrency);
  }

  const allcurrency = [
    {
      options: [{ label: "₸", value: "(₸)" }],
    },
  ];

  document.title = "CRMBEK : Заказ";

  const [orderData, setOrderData] = useState({
    id: "",
    number: "",
    code: "",
    phone: "",
    storage: null,
    storage_obj: null,
    status: null,
    status_obj: null,
    customer: "",
    address: "",
    ordered_date: null,
    comment: "",
    sales_channel: null,
    sales_channel_obj: null,
    order_products: [],
  });
  const [productData, setProductData] = useState({
    title: "",
    code: "",
    // self_cost: null,
    image: null,
    price: null,
    parameters: [],
  });

  const [newSupplier, setNewSupplier] = useState({
    name: "",
  });

  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  // ДАННЫЕ
  const [storages, setStorages] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [sales_channels, setSalesChannel] = useState([]);

  const [supplierInputName, setSupplierInputName] = useState("");
  const [supplierInputPhone, setSupplierInputPhone] = useState("");
  const [supplierInputAddress, setSupplierInputAddress] = useState("");

  const [statusInputName, setStatusInputName] = useState("");
  const [statusInputMark, setStatusInputMark] = useState("neutral");

  // const [selectedSupplier, setSelectedSupplier] = useState("");

  const [date, setDate] = useState(orderData.ordered_date);
  const [selectedItem, setSelectedItem] = useState(null);
  const [products, setProducts] = useState([]);
  const [supplier, setSupplier] = useState([]);
  // МОДАЛЬНЫЕ ОКНА
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAddSupplier, setIsAddSupplier] = useState(false);
  const [isAddStatus, setIsAddStatus] = useState(false);
  const [isModalSupplierOpen, setIsModalSupplierOpen] = useState(false);
  const [isModalStatusOpen, setIsModalStatusOpen] = useState(false);
  const [isModalProductsOpen, setIsModalProductsOpen] = useState(false);
  const [accounts, setAccounts] = useState({});
  const fetchAccounts = () => {
    getAccountsApi()
      .then((data) => {
        setAccounts(data);
      })
      .catch(() => {
        // toast.error("Не удалось получить список аккаунтов", {
        //   position: "top-right",
        // });
      });
  };
  const hasPermissions = (permissions) => {
    return (
      accounts.results &&
      accounts.current &&
      permissions.includes(accounts.results[accounts.current].access)
    );
  };

  useEffect(() => {
    const fetchOrderData = async () => {
      try {
        const response = await getOrderDetailApi({ id: orderId });
        if (response) {
          setOrderData(response);
          // console.log("response.data: " + response);
        } else {
          console.error("Failed to fetch reception data");
        }
      } catch (error) {
        console.error("Error fetching reception data:", error);
      }
    };
    fetchOrderData();
    fetchAccounts();
  }, [orderId]);

  useEffect(() => {
    getProductsApi({ all: true })
      .then((response) => {
        setProducts(response);
      })
      .catch((error) => {})
      .finally(() => {
        setProductsLoading(false);
      });

    getStoragesApi()
      .then((response) => {
        setStorages(response);
      })
      .catch((error) => {});

    fetchStatuses();

    getSalesChannelsApi()
      .then((response) => {
        setSalesChannel(response);
      })
      .catch((error) => {});

    fetchSuppliers();
  }, []); // Empty dependency array to run this effect only on mount

  ////////////ДОБАВЛЯЕМ ПОСТАВЩИКА////////////////////

  const handleSupplierInputNameChange = (event) => {
    setSupplierInputName(event.target.value);
  };
  const handleSupplierInputPhoneChange = (event) => {
    setSupplierInputPhone(event.target.value);
  };
  const handleSupplierInputAddressChange = (event) => {
    setSupplierInputAddress(event.target.value);
  };

  const handleStatusInputNameChange = (event) => {
    // setErrors({});
    setStatusInputName(event.target.value);
  };
  const handleStatusInputMarkChange = (event) => {
    setStatusInputMark(event.target.value);
  };

  const fetchSuppliers = () => {
    getCustomersApi()
      .then((response) => {
        setSupplier(response);
      })
      .catch((error) => {
        // console.error("Error fetching products:", error);
      });
  };
  const fetchStatuses = () => {
    getStatusesApi({is_returns: isReturns})
      .then((response) => {
        setStatuses(response);
      })
      .catch((error) => {});
  };

  const addSupplier = async () => {
    let supplierData = {
      name: supplierInputName.trim(),
      phone: supplierInputPhone.trim(),
      address: supplierInputAddress.trim(),
    };

    await addCustomerApi(supplierData)
      .then((response) => {
        setSupplierInputName("");
        setSupplierInputPhone("");
        setSupplierInputAddress("");

        // setSelectedSupplier(response.name); // TODO

        handleSupplierClick(response);
        fetchSuppliers();

        setIsAddSupplier(false);
        setIsModalSupplierOpen(false);
        toast.success("Клиент успешно добавлен", { position: "top-right" });
      })
      .catch((error) => {
        if (error?.response?.data?.errors && error?.response?.status === 400) {
          setErrors(error.response.data.errors);
        } else {
          toast.error("Не удалось добавить клиента", { position: "top-right" });
        }
      });
  };

  const AddOrder = () => {
    toast.success("Заказ успешно сохранён", { position: "top-right" });
  };
  const addStatus = async () => {
    let statusData = {
      name: statusInputName.trim(),
      mark: statusInputMark,
    };

    await addStatusApi(statusData)
      .then((response) => {
        // setSelectedStatus(response.name);
        // handleSupplierClick(response);
        fetchStatuses();

        setIsAddStatus(false);
        // setIsModalStatusOpen(false);

        setStatusInputName("");
        setStatusInputMark("neutral");

        toast.success("Статус успешно добавлен", { position: "top-right" });
      })
      .catch((error) => {
        if (error?.response?.data?.errors && error?.response?.status === 400) {
          setErrors(error.response.data.errors);
        } else {
          toast.error("Не удалось добавить статус", { position: "top-right" });
        }
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProductData({ ...productData, [name]: value });
  };

  //////////////////////////////////
  const updateOrder = async (updatedData) => {
    try {
      setErrors({});
      const response = await updateOrderApi(orderId, updatedData);
      setOrderData(response);
      // if (response.draft === false) {
      //   history('/receptions', {
      //     state: { message: "Приёмка успешно загружена. Остатки были обновлены" },
      //   });
      // }
    } catch (error) {
      if (error?.response?.data?.errors && error?.response?.status === 400) {
        setErrors(error.response.data.errors);
      } else {
        toast.error("Не удалось обновить заказ");
      }
    }
    setBlockButton(false);
  };

  const updateStatus = async (statusData) => {
    try {
      statusData.name = statusData.name.trim();
      setErrors({});
      const response = await updateStatusApi(statusData);
      // setOrderData(response);
    } catch (error) {
      if (error?.response?.data?.errors && error?.response?.status === 400) {
        setErrors(error.response.data.errors);
      } else {
        toast.error("Не удалось обновить статус");
      }
    }
  };
  const handleDeleteStatus = async (id) => {
    try {
      await deleteStatusApi({ id });
      toast.success("Статус успешно удалён");
      fetchStatuses();
    } catch (error) {
      toast.error("Не удалось удалить статус");
    }
  };

  const handleProductChange = () => {
    setIsModalOpen(true);
    setSearchModalValue("");
  };
  const handleProductClose = () => {
    setIsModalOpen(false);
    setSearchModalValue("");
  };

  const handleSupplierChange = () => {
    setIsModalSupplierOpen(true);
    setSearchModalValue("");
  };
  const handleSupplierClose = () => {
    setIsModalSupplierOpen(false);
    setSearchModalValue("");
  };
  const handleStatusClose = () => {
    setIsModalStatusOpen(false);
  };

  const handleAddProductChange = () => {
    setProductData({});
    validationAddProduct.resetForm();
    setErrors({});
    setSelectedFile(null);
    setSelectedImage("");
    setIsModalProductsOpen(true);
  };

  const handleCloseAddProduct = () => {
    setIsModalProductsOpen(false);
  };
  const handleAddSupplier = () => {
    setErrors({});
    setSupplierInputName("");
    setSupplierInputPhone("");
    setSupplierInputAddress("");
    setIsAddSupplier(true);
  };
  const handleAddStatus = () => {
    setErrors({});
    setStatusInputName("");
    setStatusInputMark("neutral");
    setIsAddStatus(true);
  };

  const handleAddSupplierClose = () => {
    setErrors({});
    setIsAddSupplier(false);
  };
  const handleAddStatusClose = () => {
    setErrors({});
    setIsAddStatus(false);
  };

  const handleAddOrderProduct = async (selected) => {
    // alert(selected);
    if (selected) {
      const productId = selected?.product_data
        ? selected.product_data.id
        : selected.id;
      const newOrderProduct = {
        product: productId,
      };

      const updatedData = {
        ...orderData,
        order_products: [...orderData.order_products, newOrderProduct],
      };
      await updateOrder(updatedData);
    }
    setIsModalOpen(false);
  };

  const [updateTimeout, setUpdateTimeout] = useState(null);
  const [blockButton, setBlockButton] = useState(false);

  const handleFieldChange = (index, field, value) => {
    setBlockButton(true);
    const updatedProducts = [...orderData.order_products];
    updatedProducts[index][field] = value;

    // setOrderData((prevData) => ({
    //   ...prevData,
    //   order_products: updatedProducts,
    // }));
    if (updateTimeout) {
      clearTimeout(updateTimeout);
    }
    const newUpdateTimeout = setTimeout(() => {
      updateOrder({ ...orderData, order_products: updatedProducts });
    }, 3000);

    setUpdateTimeout(newUpdateTimeout);
  };

  const handleQtyChange = (index, value) => {
    if (value > 9999) {
      value = 9999;
    }
    if (value < 1) {
      value = 1;
    }
    handleFieldChange(index, "quantity", value);
  };

  const handleSelfCostChange = (index, value) => {
    handleFieldChange(index, "price", value);
  };

  const handleStorageChange = (selectedStorageId) => {
    const updatedOrderData = {
      ...orderData,
      storage: selectedStorageId,
    };
    updateOrder(updatedOrderData);
  };

  const handleStatusChange = (selectedStatus) => {
    setSearchValue("");
    setSearchModalValue("");
    if (selectedStatus !== "configure") {
      const updatedOrderData = {
        ...orderData,
        status: selectedStatus,
      };
      updateOrder(updatedOrderData);
    } else {
      setIsModalStatusOpen(true);
    }
  };

  const handleSalesChannelChange = (selectedSalesChannel) => {
    const updatedOrderData = {
      ...orderData,
      sales_channel: selectedSalesChannel,
    };
    updateOrder(updatedOrderData);
  };

  const handleCommentChange = (newCommment) => {
    const updatedOrderData = {
      ...orderData,
      comment: newCommment,
    };
    setOrderData(updatedOrderData);
  };
  const handleSupplierWriteChange = (newName) => {
    const updatedOrderData = {
      ...orderData,
      customer: newName,
    };
    setOrderData(updatedOrderData);
  };
  const handlePhoneChange = (newPhone) => {
    const updatedOrderData = {
      ...orderData,
      phone: newPhone,
    };
    setOrderData(updatedOrderData);
  };
  const handleAddressChange = (newAddress) => {
    const updatedOrderData = {
      ...orderData,
      address: newAddress,
    };
    setOrderData(updatedOrderData);
  };
  const handleCodeChange = (newCode) => {
    const updatedOrderData = {
      ...orderData,
      code: newCode,
    };
    setOrderData(updatedOrderData);
  };

  const handleUpdateOrderData = () => {
    updateOrder(orderData);
  };

  const handleCopyPhoneClick = () => {
    clipboardCopy(orderData.phone)
      .then(() => {
        toast.success("Телефон скопирован в буфер!");
      })
      .catch((err) => {});
  };
  const handleCopyCodeClick = () => {
    clipboardCopy(orderData.code)
      .then(() => {
        toast.success("Каспи номер скопирован в буфер!");
      })
      .catch((err) => {});
  };

  const handleRemoveReceptionProduct = async (index) => {
    const updatedProducts = [...orderData.order_products];
    updatedProducts.splice(index, 1);

    const updatedData = {
      ...orderData,
      order_products: updatedProducts,
    };
    await updateOrder(updatedData);
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //
  //   const updatedData = {
  //     ...orderData,
  //     // draft: false,
  //   };
  //   await updateOrder(updatedData);
  // };

  const handleSupplierClick = (supplier) => {
    const updatedSupplier = {
      ...orderData,
      customer: supplier.name,
      phone: supplier.phone,
      address: supplier.address,
    };
    // if (!orderData.phone) {
    //   if (supplier.phone) {
    //     updatedSupplier.phone = supplier.phone;
    //   }
    // }
    updateOrder(updatedSupplier);
    // setSelectedSupplier(supplier.name);

    setIsModalSupplierOpen(false);
    setIsAddSupplier(false);
  };

  ///////////////////////////
  const orderProducts = orderData.order_products;
  const filteredProducts = products.filter((product) => {
    return !orderProducts.some(
      (orderProduct) => orderProduct.product_data.id === product.id
    );
  });

  const handleProductSelect = (product) => {
    handleAddOrderProduct(product);
    setSearchValue("");
  };

  //////ОБНОВЛЕНИЕ ДАТЫ//////
  const handleDateChange = (event) => {
    const newDate = event.target.value;
    const newOrderData = {
      ...orderData,
      ordered_date: newDate,
    };
    updateOrder(newOrderData);
  };

  const newOrderedDate =
    orderData.ordered_date && orderData.ordered_date.includes("Z")
      ? orderData.ordered_date.replace("Z", "")
      : orderData.ordered_date;

  //////////////ДАТА КОТОРАЯ СЕГОДНЯ///////////////////
  const [currentDateTime, setCurrentDateTime] = useState(getCurrentDateTime());

  const handleDate = (e) => {
    setCurrentDateTime(e.target.value);
  };

  ///////////СОЗДАНИЕ ТОВАРОВ//////////////////
  const [selectedFiles, setselectedFiles] = useState([]);
  const [files, setFiles] = useState([]);
  const [fields, setFields] = useState([]);
  const [input1, setInput1] = useState("");
  const [input2, setInput2] = useState("");
  const [isEditing, setIsEditing] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleAcceptedFiles = (files) => {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    setselectedFiles(files);
  };

  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  const handleAddField = () => {
    setFields([...fields, { input1, input2 }]);
    const newParameter = { name: input1, value: input2 };

    // Обновите parameters внутри productData
    setProductData({
      ...productData,
      parameters: [...productData.parameters, newParameter],
    });

    // Очистите input1 и input2lue
    setInput1("");
    setInput2("");
  };

  const handleDeleteField = (index) => {
    const updatedFields = [...fields];
    updatedFields.splice(index, 1);
    setFields(updatedFields);
  };

  ///////////////ПОИСК ПО ТОВАРАМ///////////////////////////////
  const [searchValue, setSearchValue] = React.useState("");
  const [searchModalValue, setSearchModalValue] = React.useState("");

  const onChangeValue = (event) => {
    setSearchValue(event.target.value);
  };
  const onChangeModalValue = (event) => {
    setSearchModalValue(event.target.value);
  };
  /////////////////ОБРАБОТЧИК КЛИКОВ//////////////
  const resultsRef = useRef(null);
  // const resultsModalRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (resultsRef.current && !resultsRef.current.contains(event.target)) {
        setSearchValue(""); // Set searchValue to an empty string when clicking outside
      }
      // if (resultsModalRef.current && !resultsModalRef.current.contains(event.target)) {
      //   setSearchModalValue(""); // Set searchValue to an empty string when clicking outside
      // }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  //////////////РАСПЕЧАТАТЬ////////////////////////////////
  // const handlePrint = () => {
  //   window.print(); // Вызываем функцию для печати страницы
  // };
  ////////////////УДАЛИТЬТОВАР////////////////////////
  const handleDeleteProduct = (index) => {
    setBlockButton(true);

    const updatedOrderProducts = [...orderData.order_products];
    updatedOrderProducts.splice(index, 1);

    const updatedData = {
      ...orderData,
      order_products: updatedOrderProducts,
    };

    setOrderData(updatedData)

    if (updateTimeout) {
      clearTimeout(updateTimeout);
    }
    const newUpdateTimeout = setTimeout(() => {
      updateOrder(updatedData);
      // setBlockButton(false)
    }, 3000);
    setUpdateTimeout(newUpdateTimeout);
  };

  const [errors, setErrors] = useState({});
  const [selectedImage, setSelectedImage] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);

  const validationAddProduct = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      image: (productData && productData.image) || "",
      title: (productData && productData.title) || "",
      code: (productData && productData.code) || "",
      description: (productData && productData.description) || "",
      price: (productData && productData.price) || "",
    },
    validationSchema: Yup.object({
      // image: Yup.string().required("Пожалуйста выберите картинку"),
      title: Yup.string().required("Пожалуйста введите название Товара"),
      code: Yup.string().required("Пожалуйста введите Артикул"),
      price: Yup.number().required("Пожалуйста выставите Цену продажи"),
    }),
    validateOnChange: true,

    validate: (values) => {
      const errors = {};
      if (values.price === 0) {
        values.price = "";
      } else if (values.price < 0) {
        errors.price = "Цена продажи не может быть отрицательной";
      } else if (values.price > 9999999) {
        errors.price = "Цена продажи не может быть больше 9999999";
      }
      return errors;
    },

    onSubmit: (values) => {
      setErrors({});

      const newProductData = new FormData();
      if (selectedFile) {
        newProductData.append("image", selectedFile);
      }
      newProductData.append("title", values.title);
      newProductData.append("code", values.code);
      newProductData.append("description", values.description);
      newProductData.append("price", values.price);

      createProductApi(newProductData)
        .then((createdProductData) => {
          // Call the function to update the element in teamList
          let updatedProductsData = [createdProductData, ...products];
          setProducts(updatedProductsData);
          setIsModalProductsOpen(false);
          toast.success("Товар был создан успешно", { position: "top-right" });
        })
        .catch((error) => {
          if (
            error?.response?.data?.errors &&
            error?.response?.status === 400
          ) {
            setErrors(error.response.data.errors);
          } else {
            toast.error("Не удалось создать товар", { position: "top-right" });
          }
        });

      if (!errors) {
        validationAddProduct.resetForm();
      }
    },
  });

  ///////////////////////////////////////////////
  return (
    <div className="page-content">
      <Container fluid>
        <Row className="justify-content-center pb-5">
          {/* px-5  */}
          <Col xxl={9} style={{ marginBottom: "25px" }}>
            <Card className={styles.card}>
              <Form
                // onSubmit={handleSubmit}
                onSubmit={(event) => event.preventDefault()}
                // className="needs-validation"
                className={`needs-validation ${styles["needs-validation"]}`}
                id="invoice_form"
              >
                <CardBody className="border-bottom border-bottom-dashed p-4">
                  <div className={styles.header_order}>
                    <div className={styles.container_flex_select}>
                      <div className={styles.order_title}>
                        <Link to={isReturns ? "/returns": '/orders'} style={{ width: "30px" }}>
                          <i
                            className="ri-reply-fill"
                            style={{ fontSize: "20px" }}
                          ></i>
                        </Link>
                        <h4 style={{ paddingRight: "15px", marginBottom: 0 }}>
                          {isReturns ? 'Возврат': 'Заказ'} #
                          {orderData?.number
                            ? padNumberWithZeros(orderData.number, 3)
                            : "000"}
                        </h4>
                      </div>
                      <div>
                        <Input
                          className={styles.exampleInputdate}
                          id="exampleInputdate"
                          type="datetime-local"
                          min="2023-01-01T00:00"
                          max="2030-01-01T00:00"
                          required
                          value={newOrderedDate || getCurrentDateTime()}
                          onChange={handleDateChange}
                          invalid={!!errors?.ordered_date}
                          // disabled={!hasPermissions(["owner", "admin"])}
                        />
                        {errors && errors?.ordered_date ? (
                          <FormFeedback className={styles.feedback}>
                            <span className="lh-1">{errors.ordered_date}</span>
                          </FormFeedback>
                        ) : null}
                      </div>
                    </div>

                    <div className={styles.flex_select}>
                      <div className={styles.select_error}>
                        <select
                          style={{
                            minWidth: "165px",
                            backgroundColor:
                              orderData?.status_obj?.mark === "positive"
                                ? "rgb(195 255 224)"
                                : orderData?.status_obj?.mark === "refusal"
                                ? "rgb(255 213 213)"
                                : orderData?.status_obj?.mark === "neutral"
                                ? "lightgoldenrodyellow"
                                : "rgba(47, 218, 255, 0.28)",
                            boxShadow:
                              orderData?.status_obj?.mark === "positive"
                                ? "0 0 0 0.25rem rgb(216 253 235)"
                                : orderData?.status_obj?.mark === "refusal"
                                ? "0 0 0 0.25rem rgb(251 227 227)"
                                : orderData?.status_obj?.mark === "neutral"
                                ? "0 0 0 0.25rem #f9f9de"
                                : "0 0 0 0.25rem rgb(117 226 249 / 28%)",
                            border:
                              orderData?.status_obj?.mark === "positive"
                                ? "1px solid rgb(119 247 142)"
                                : orderData?.status_obj?.mark === "refusal"
                                ? "1px solid rgb(255 185 185)"
                                : orderData?.status_obj?.mark === "neutral"
                                ? "1px solid rgb(243 239 90)"
                                : "1px solid rgb(119 234 247)",
                          }}
                          value={orderData.status || ""}
                          onChange={(e) => {
                            const selectedStatus = e.target.value;
                            handleStatusChange(selectedStatus);
                          }}
                          className={`form-select ${styles["select_new"]} `}
                          aria-label="Default select example"
                        >
                          <option className={styles.clear_option} value="">
                            Новый
                          </option>
                          {statuses.map((status) => (
                            <option
                              className={styles.clear_option}
                              key={status.id}
                              value={status.id}
                            >
                              {status.name}
                              {/*{status.name.length > 17*/}
                              {/*  ? `${status.name.slice(0, 17)}...`*/}
                              {/*  : status.name}*/}
                            </option>
                          ))}

                           <hr/>
                          <option className={styles.configure_option} value="configure" style={{fontSize: '15px'}}>

                            Настроить...
                          </option>
                        </select>
                        {errors && errors?.status ? (
                          <div className={styles.error}>{errors.status}</div>
                        ) : null}
                      </div>

                      <div className={styles.select_error}>
                        <select
                          value={orderData?.storage || ""}
                          onChange={(e) => {
                            const selectedStorageId = e.target.value;
                            handleStorageChange(selectedStorageId);
                          }}
                          style={
                            !orderData.storage ? { fontWeight: "bold" } : null
                          }
                          className={`form-select ${styles["select_width"]}`}
                          aria-label="Default select example"
                          disabled={
                            // !hasPermissions(["owner", "admin"]) ||
                            ["positive", "refusal"].includes(
                              orderData?.status_obj?.mark
                            )
                          }
                        >
                          <option style={{ fontWeight: "bold" }} value="">
                            Склад
                          </option>
                          {storages.map((storage) => (
                            <option key={storage.id} value={storage.id}>
                              {storage.name}
                            </option>
                          ))}
                          {/* Добавляем опцию для архивного склада */}
                          {orderData?.storage_obj?.id &&
                              !storages.some((storage) => storage.id === orderData.storage_obj.id) && (
                              <option key={orderData?.storage_obj.id} value={orderData.storage_obj.id}>
                                {orderData.storage_obj?.full_name}
                              </option>
                          )}
                        </select>
                        {errors && errors?.storage ? (
                          <div className={styles.error}>{errors.storage}</div>
                        ) : null}
                      </div>

                      <div className={styles.select_error}>
                        <select
                          value={orderData.sales_channel || ""}
                          onChange={(e) => {
                            const selectedSalesChannel = e.target.value;
                            handleSalesChannelChange(selectedSalesChannel);
                          }}
                          style={
                            !orderData.sales_channel
                              ? { fontWeight: "bold" }
                              : null
                          }
                          className={`form-select ${styles["select_width"]}`}
                          aria-label="Default select example"
                          disabled={
                            // !hasPermissions(["owner", "admin"]) ||
                            ["positive", "refusal"].includes(
                              orderData?.status_obj?.mark
                            )
                          }
                        >
                          <option style={{ fontWeight: "bold" }} value="">
                            Канал продаж
                          </option>
                          {sales_channels.map((sales_channel) => (
                            <option
                              key={sales_channel.id}
                              value={sales_channel.id}
                            >
                              {sales_channel.name}
                            </option>
                          ))}
                        </select>
                        {errors && errors?.sales_channel ? (
                          <div className={styles.error}>
                            {errors.sales_channel}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <Row>
                    <Col xxl={1}>
                      <PrintButton id={orderData.id} number={orderData.number} documentType={'order'} />
                    </Col>
                  </Row>
                  <Row>
                    <div className={styles.block_for_carts_order}>
                      <div className={styles.cart_order_block}>
                        {/* <div className={styles.cart_provider_1}> */}
                        <div className={styles.cart_provider_1}>
                          <Label htmlFor="basiInput" className="form-label">
                            <h5 className={styles.input_label}>Имя Клиента</h5>
                          </Label>
                          <div className={styles.provider}>
                            {" "}
                            <Input
                              type="text"
                              // readOnly
                              className={`form-control ${styles["input"]}`}
                              id="basiInput"
                              style={{ width: "85%" }}
                              value={
                                // selectedSupplier
                                //   ? selectedSupplier
                                //   :
                                orderData?.customer || ""
                              }
                              onChange={(e) =>
                                handleSupplierWriteChange(e.target.value)
                              }
                              onBlur={() => handleUpdateOrderData()}
                              invalid={!!errors?.customer}
                              // disabled={!hasPermissions(["owner", "admin"])}
                            />
                            <i
                              type="button"
                              onClick={
                                // (hasPermissions(["owner", "admin"]) &&
                                  handleSupplierChange
                                  // || null
                              }
                              className={`ri-search-line fs-18 ${styles["search"]}`}
                              style={{
                                padding: "1px 5px",
                                margin: "0 0 0 13px",
                              }}
                            ></i>
                          </div>
                          {errors && errors?.customer ? (
                            <div className={styles.errors_text_fields}>
                              {errors.customer}
                            </div>
                          ) : null}
                          {/* </div> */}
                        </div>
                        <div className={styles.cart_provider}>
                          <Label htmlFor="basiInput" className="form-label">
                            <h5 className={styles.input_label}>Телефон</h5>
                          </Label>
                          <div className={styles.provider}>
                            {" "}
                            <Input
                              type="text"
                              className={`form-control ${styles["input"]}`}
                              id="basiInput"
                              style={{ width: "85%" }}
                              value={orderData.phone || ""}
                              onChange={(e) =>
                                handlePhoneChange(e.target.value)
                              }
                              onBlur={() => handleUpdateOrderData()}
                              invalid={!!errors?.phone}
                              // disabled={!hasPermissions(["owner", "admin"])}
                            />
                            <img
                              style={{ cursor: "pointer" }}
                              onClick={handleCopyPhoneClick}
                              alt="copy"
                              src="/copy.png"
                            />
                          </div>
                          {errors && errors?.phone ? (
                            <div className={styles.errors_text_fields}>
                              {errors.phone}
                            </div>
                          ) : null}
                        </div>
                        <div className={styles.cart_provider}>
                          <Label htmlFor="basiInput" className="form-label">
                            <h5 className={styles.input_label}>
                              Каспи номер заказа
                            </h5>
                          </Label>
                          <div className={styles.provider}>
                            {" "}
                            <Input
                              type="text"
                              className={`form-control ${styles["input"]}`}
                              id="basiInput"
                              style={{ width: "85%" }}
                              value={orderData.code || ""}
                              onChange={(e) => handleCodeChange(e.target.value)}
                              onBlur={() => handleUpdateOrderData()}
                              invalid={!!errors?.code}
                              // disabled={!hasPermissions(["owner", "admin"])}
                            />
                            <img
                              style={{ cursor: "pointer" }}
                              onClick={handleCopyCodeClick}
                              alt="copy"
                              src="/copy.png"
                            />
                          </div>
                          {errors && errors?.code ? (
                            <div className={styles.errors_text_fields}>
                              {errors.code}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className={styles.cart_order_block_2}>
                        <div className={styles.cart_provider_orders}>
                          <Label
                            style={{ display: "flex", alignItems: "center" }}
                            htmlFor="basiInput"
                            className="form-label"
                          >
                            <img alt="map" src="/map.png" />
                            <h5 className={styles.input_label}>Адрес</h5>
                          </Label>
                          <div className={styles.provider}>
                            {" "}
                            <textarea
                              placeholder="Введите адрес"
                              rows="4"
                              cols="50"
                              value={orderData.address || ""}
                              onChange={(e) =>
                                handleAddressChange(e.target.value)
                              }
                              onBlur={() => handleUpdateOrderData()}
                              // disabled={!hasPermissions(["owner", "admin"])}
                              // invalid={!!errors?.code}
                            ></textarea>
                          </div>
                          {errors && errors?.address ? (
                            <div className={styles.errors_text_fields}>
                              {errors.address}
                            </div>
                          ) : null}
                        </div>
                        <div className={styles.cart_provider_orders}>
                          <Label
                            style={{ display: "flex", alignItems: "center" }}
                            htmlFor="basiInput"
                            className="form-label"
                          >
                            <img
                              style={{ width: "21px", marginRight: "5px" }}
                              alt="map"
                              src="/comment.png"
                            />
                            <h5 className={styles.input_label}>Комментарий</h5>
                          </Label>
                          <div className={styles.provider}>
                            {" "}
                            <textarea
                              placeholder="Прокомментируйте заказ"
                              rows="4"
                              cols="50"
                              value={orderData.comment || ""}
                              onChange={(e) =>
                                handleCommentChange(e.target.value)
                              }
                              onBlur={() => handleUpdateOrderData()}
                              // disabled={!hasPermissions(["owner", "admin"])}
                            ></textarea>
                          </div>
                          {errors && errors?.comment ? (
                            <div className={styles.errors_text_fields}>
                              {errors.comment}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </Row>
                </CardBody>

                <CardBody className="p-4">
                  {errors?.error ? (
                    <div style={{ color: "#ed5e5e" }}>
                      <ul>
                        <li>{errors.error}</li>
                      </ul>
                    </div>
                  ) : null}
                  <div
                    className={`table-responsive ${styles["table-responsive"]}`}
                  >
                    <div className={styles.addProduct}>
                      <div className={styles.input_prod}>
                        <i
                          style={
                            // !hasPermissions(["owner", "admin"]) ||
                            blockButton ||
                            ["positive", "refusal"].includes(
                              orderData?.status_obj?.mark
                            )
                              ? { backgroundColor: "#eff2f7" }
                              : null
                          }
                          className={`ri-search-line ${styles["add_prod"]}`}
                        ></i>
                        <input
                          autoComplete="off"
                          type="text"
                          className={`form-control ${styles["input"]}`}
                          id="basiInput"
                          onChange={onChangeValue}
                          value={searchValue}
                          style={{ border: "none" }}
                          disabled={
                            // !hasPermissions(["owner", "admin"]) ||
                            blockButton ||
                            ["positive", "refusal"].includes(
                              orderData?.status_obj?.mark
                            )
                          }
                          readOnly={
                            // !hasPermissions(["owner", "admin"]) ||
                            ["positive", "refusal"].includes(
                              orderData?.status_obj?.mark
                            )
                          }
                          placeholder="Добавить товар"
                        />
                      </div>

                      <button
                        type="button"
                        className={styles.catalog}
                        disabled={blockButton}
                        onClick={
                          // hasPermissions(["owner", "admin"]) &&
                          !["positive", "refusal"].includes(
                            orderData?.status_obj?.mark
                          )
                            ? handleProductChange
                            : () => {}
                        }
                      >
                        Каталог
                      </button>
                    </div>
                    {errors?.order_products &&
                    typeof errors?.order_products[0] === "string" ? (
                      <div style={{ color: "#ed5e5e", marginTop: "10px" }}>
                        <ul>
                          <li>{errors.order_products}</li>
                        </ul>
                      </div>
                    ) : null}

                    {searchValue ? (
                      filteredProducts.filter(
                        (product) =>
                          product.title
                            .toLowerCase()
                            .includes(searchValue.toLowerCase()) ||
                          product.code
                            .toLowerCase()
                            .includes(searchValue.toLowerCase())
                      ).length > 0 || productsLoading ? (
                        <div ref={resultsRef} className={styles.results}>
                          <table
                            className="table-nowrap table table-hover"
                            style={{ width: "100%" }}
                          >
                            <thead>
                              <tr style={{ fontSize: "14px" }}>
                                <th>Название</th>
                                <th>Артикул</th>
                                <th>Фото</th>
                              </tr>
                            </thead>
                            <tbody
                              className={styles.table_modal_product}
                              style={{ verticalAlign: "baseline" }}
                            >
                              {productsLoading ? ( // Show loader only when loading
                                <Loader />
                              ) : (
                                filteredProducts
                                  .filter(
                                    (product) =>
                                      product.title
                                        .toLowerCase()
                                        .includes(searchValue.toLowerCase()) ||
                                      product.code
                                        .toLowerCase()
                                        .includes(searchValue.toLowerCase())
                                  )
                                  .slice(0, 5)
                                  .map((product) => (
                                    <tr
                                      key={product.id}
                                      style={{ fontSize: "14px" }}
                                    >
                                      <th
                                        style={{
                                          cursor: "pointer",
                                          color: "#0066ff",
                                          lineHeight: 1,
                                        }}
                                        onClick={() =>
                                          handleProductSelect(product)
                                        }
                                      >
                                        <span
                                          style={{
                                            wordWrap: "break-word",
                                            whiteSpace: "normal",
                                          }}
                                        >
                                          {product.title}
                                        </span>
                                      </th>
                                      <th>{product.code}</th>
                                      <th>
                                        {product.image || product?.url_image ? (
                                          <div
                                            style={{
                                              height: "30px",
                                              textAlign: "center",
                                              width: "35px",
                                            }}
                                          >
                                            <img
                                              src={product.image || product?.url_image}
                                              alt=" "
                                              style={{
                                                width: "auto",
                                                maxWidth: "100%",
                                                maxHeight: "100%",
                                              }}
                                            />
                                          </div>
                                        ) : (
                                          <span>-</span>
                                        )}
                                      </th>
                                    </tr>
                                  ))
                              )}
                            </tbody>
                          </table>
                        </div>
                      ) : (
                        <div className={styles.results}>Ничего не найдено</div>
                      )
                    ) : null}

                    <div class="table-wrapper" style={{overflowX: 'auto', maxWidth: '100%'}}>
                      <Table
                        className={`invoice-table table-borderless table-nowrap mb-0 mt-4 ${styles["table_detail_reception"]}`}
                      >
                        <thead className="align-middle">
                          <tr>
                            {/* <th scope="col">Картинка</th> */}
                            <th scope="col" style={{ width: "40%" }}>
                              Состав заказа:
                            </th>
                            <th scope="col" style={{ width: "140px" }}>
                              Кол-во
                            </th>
                            <th scope="col" style={{ width: "140px" }}>
                              Остаток
                            </th>
                            <th scope="col" style={{ width: "120px" }}>
                              <div className="d-flex currency-select input-light align-items-center">
                                Цена продажи
                              </div>
                            </th>
                            <th scope="col" style={{ width: "140px" }}>
                              Сумма
                            </th>
                            {
                              // hasPermissions(["owner", "admin"]) &&
                              !["positive", "refusal"].includes(
                                orderData?.status_obj?.mark
                              ) && (
                                <th
                                  scope="col"
                                  className="text-end"
                                  // style={{ width: "105px" }}
                                ></th>
                              )}
                          </tr>
                        </thead>
                        <tbody id="newlink" style={{ verticalAlign: "baseline" }}>
                          {orderData.order_products.map((oProduct, index) => (
                            <tr key={index} id="1" className="product">
                              <td className="text-start">
                                <div>
                                  {" "}
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "left",
                                      alignItems: "center",
                                    }}
                                  >
                                    <div
                                      style={{
                                        height: "12px",
                                        width: "35px",
                                        marginRight: "20px",
                                        verticalAlign: "middle",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                    >
                                      {(oProduct?.product_data?.image || oProduct?.product_data?.url_image) && (
                                        <div
                                          style={{
                                            textAlign: "center",
                                            width: "35px",
                                          }}
                                        >
                                          <img
                                            src={oProduct.product_data.image || oProduct?.product_data?.url_image}
                                            alt="Фото товара"
                                            style={{
                                              width: "auto",
                                              maxWidth: "100%",
                                              maxHeight: "35px",
                                            }}
                                          />
                                        </div>
                                      )}
                                    </div>
                                    <span
                                      style={{
                                        maxWidth: "200px",
                                        minWidth: "170px",
                                        wordWrap: "break-word",
                                        whiteSpace: "normal",
                                        lineHeight: 1,
                                      }}
                                    >
                                      {oProduct?.product_data?.title}
                                    </span>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div
                                  className="input-step"
                                  style={
                                    // !hasPermissions(["owner", "admin"]) ||
                                    ["positive", "refusal"].includes(
                                      orderData?.status_obj?.mark
                                    )
                                      ? { border: 0 }
                                      : null
                                  }
                                >
                                  <button
                                    style={{
                                      display:
                                        // hasPermissions(["owner", "admin"]) &&
                                        !["positive", "refusal"].includes(
                                          orderData?.status_obj?.mark
                                        )
                                          ? "block"
                                          : "none",
                                    }}
                                    type="button"
                                    className="minus"
                                    onClick={() =>
                                      handleQtyChange(
                                        index,
                                        oProduct.quantity - 1
                                      )
                                    }
                                  >
                                    –
                                  </button>
                                  <input
                                    type="number"
                                    style={{ width: "3rem" }}
                                    className="product-quantity"
                                    id="product-qty-1"
                                    value={oProduct.quantity}
                                    onChange={(e) =>
                                      handleQtyChange(index, e.target.value)
                                    }
                                    // readOnly
                                  />
                                  <button
                                    style={{
                                      display:
                                        // hasPermissions(["owner", "admin"]) &&
                                        !["positive", "refusal"].includes(
                                          orderData?.status_obj?.mark
                                        )
                                          ? "block"
                                          : "none",
                                    }}
                                    type="button"
                                    className="plus"
                                    onClick={() =>
                                      handleQtyChange(
                                        index,
                                        oProduct.quantity + 1
                                      )
                                    }
                                  >
                                    +
                                  </button>
                                </div>
                              </td>
                              <td className="text-start">
                                <div
                                  className={`form-control border-0 ${styles["input_form"]}`}
                                  name="product_name"
                                >
                                  {oProduct?.remaining
                                    ? oProduct.remaining
                                    : oProduct.remaining === 0
                                    ? "0"
                                    : "-"}
                                </div>
                              </td>
                              <td>
                                <Input
                                  style={{
                                    border:
                                      // !hasPermissions(["owner", "admin"]) ||
                                      (["positive", "refusal"].includes(
                                        orderData?.status_obj?.mark
                                      ) &&
                                        0),
                                    minWidth: "65px",
                                    maxWidth: "70px",
                                  }}
                                  className={`form-control product-price py-1 px-1 ${styles["input_form"]}`}
                                  readOnly={
                                    // !hasPermissions(["owner", "admin"]) ||
                                    ["positive", "refusal"].includes(
                                      orderData?.status_obj?.mark
                                    )
                                  }
                                  placeholder="0"
                                  type="text"
                                  value={oProduct?.price | 0}
                                  onChange={(e) =>
                                    handleSelfCostChange(index, e.target.value)
                                  }
                                  invalid={
                                    errors?.order_products
                                      ? errors?.order_products[index]?.price
                                      : false
                                  }
                                  maxLength={7}
                                />
                                <div className="invalid-feedback">
                                  {errors?.order_products
                                    ? errors?.order_products[index]?.price
                                    : null}
                                </div>
                                {/*{String(rProduct?.price | 0).length >= 7 && (*/}
                                {/*    <div className="invalid-feedback">*/}
                                {/*      Maximum of 6 characters reached*/}
                                {/*    </div>*/}
                                {/*)}*/}
                              </td>
                              <td className="text-end">
                                <div>
                                  <input
                                    style={{
                                      minWidth: "70px",
                                    }}
                                    type="text"
                                    className={`form-control border-0 product-line-price ${styles["input_form"]}`}
                                    id="productPrice-1"
                                    placeholder="0.00 ₸"
                                    value={
                                      oProduct.final_price !== null
                                        ? oProduct.final_price + " ₸"
                                        : 0
                                    }
                                    readOnly
                                  />
                                </div>
                              </td>
                              {
                                // hasPermissions(["owner", "admin"]) &&
                                !["positive", "refusal"].includes(
                                  orderData?.status_obj?.mark
                                ) && (
                                  <td
                                    style={{ position: "relative" }}
                                    className="product-removal"
                                  >
                                    <button
                                      variant="outlined"
                                      color="secondary"
                                      onClick={() => handleDeleteProduct(index)}
                                      // onClick={() =>
                                      //   handleRemoveReceptionProduct(index)
                                      // }
                                      className="btn btn-soft-danger btn btn-secondary"
                                      // style={{
                                      //   padding: "4px 10px",
                                      //   display: orderData.draft
                                      //     ? "block"
                                      //     : "none",
                                      // }}
                                      style={{
                                        padding: "4px 10px",
                                        // position: "absolute",
                                        // top: "10%",
                                        // right: "30%",
                                      }}
                                    >
                                      <i className="ri-delete-bin-2-line"></i>
                                    </button>
                                  </td>
                                )}
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                    <div className={styles.total_block}>
                      <div className={styles.total}>
                        <h3 style={{ color: "black", marginRight: "10px" }}>
                          Итого:
                        </h3>
                        <span
                          style={{ fontSize: "20px" }}
                          type="text"
                          className={`fs-3 text border-0 ${styles["input_form"]}`}
                          id="productPrice-1"
                          placeholder="0.00 ₸"
                          // value={rate * count + " ₸"}
                          readOnly
                        >
                          {orderData?.final_price
                            ? orderData.final_price + " ₸"
                            : 0 + " ₸"}
                        </span>
                      </div>
                      {/*{hasPermissions(["owner", "admin"]) && (*/}
                        <div className={styles.submit_block}>
                          <button
                            className="btn btn-success"
                            // type="submit"
                            disabled={blockButton}
                            onClick={AddOrder}
                          >
                            Сохранить заказ
                          </button>
                        </div>
                      {/*)}*/}
                    </div>
                    {/*<div className={styles.submit_block}>*/}
                    {/*  <button*/}
                    {/*    className="btn btn-success"*/}
                    {/*    // type="submit"*/}
                    {/*    onClick={handleSubmit}*/}
                    {/*    style={{*/}
                    {/*      display: receptionData.draft ? "block" : "none",*/}
                    {/*    }}*/}
                    {/*  >*/}
                    {/*    Загрузить приёмку*/}
                    {/*  </button>*/}
                    {/*</div>*/}
                  </div>
                </CardBody>

                {/*<CardBody className="p-4">*/}
                {/*  <div className={styles.comment_block}>*/}
                {/*    <div className={styles.comment_title}>*/}
                {/*      /!*<i*!/*/}
                {/*      /!*  className=" ri-message-2-line"*!/*/}
                {/*      /!*  style={{ fontSize: "40px" }}*!/*/}
                {/*      /!*></i>*!/*/}
                {/*      <img src="/comment.png" alt="comment" />*/}
                {/*      <h5 style={{ marginLeft: "10px" }}>Комментарий</h5>*/}
                {/*    </div>*/}
                {/*    <div className={styles.comment}>*/}
                {/*      <Col xxl={3} md={6} style={{ width: "100%" }}>*/}
                {/*        <textarea*/}
                {/*          // disabled={!receptionData?.draft}*/}
                {/*          // readOnly={!receptionData?.draft}*/}
                {/*          className="form-control mt-2"*/}
                {/*          id="exampleFormControlTextarea5"*/}
                {/*          value={orderData.comment || ""}*/}
                {/*          onChange={(e) => handleCommentChange(e.target.value)}*/}
                {/*          onBlur={() => handleUpdateOrderData()}*/}
                {/*          // onInput={(e) => handleCommentChange(e.target.value)}*/}
                {/*          rows="3"*/}
                {/*          style={{ width: "100%", fontSize: "16px" }}*/}
                {/*        ></textarea>*/}
                {/*      </Col>*/}
                {/*    </div>*/}
                {/*  </div>*/}
                {/*</CardBody>*/}
              </Form>
            </Card>
          </Col>
        </Row>

        {isModalOpen && (
          <Modal
            className={styles.modal_product}
            isOpen={handleProductChange}
            toggle={handleProductClose}
            style={{ "--vz-modal-width": "unset" }}
          >
            <ModalHeader toggle={handleProductClose}>
              Выберите товары
            </ModalHeader>
            <ModalBody>
              <div
                className={styles.header_modal}
                style={{ marginBottom: "15px" }}
              >
                <Button
                  onClick={handleAddProductChange}
                  color="success"
                  className="add-btn"
                  id="create-btn"
                >
                  <i className="ri-add-line align-bottom me-1"></i> Создать
                  товар
                </Button>
                <div className={styles.search_prod}>
                  <i className={`ri-search-line ${styles["add_prod"]}`}></i>
                  <input
                    style={{ border: "none" }}
                    placeholder="Поиск по товарам..."
                    type="text"
                    onChange={onChangeModalValue}
                    value={searchModalValue}
                  />
                </div>
              </div>
              <div>
                <div style={{ overflowY: "auto", height: "47vh" }}>
                  {productsLoading ? ( // Show loader only when loading
                    <Loader />
                  ) : filteredProducts.length > 0 ? (
                    filteredProducts.filter(
                      (product) =>
                        product.title
                          .toLowerCase()
                          .includes(searchModalValue.toLowerCase()) ||
                        product.code
                          .toLowerCase()
                          .includes(searchModalValue.toLowerCase())
                    ).length > 0 ? (
                      <table style={{ width: "100%" }}>
                        <thead>
                          <tr>
                            <th>Название</th>
                            <th>Артикул</th>
                            <th>Фото</th>
                          </tr>
                        </thead>
                        <tbody className={styles.table_modal_product}>
                          {filteredProducts
                            .filter(
                              (product) =>
                                product.title
                                  .toLowerCase()
                                  .includes(searchModalValue.toLowerCase()) ||
                                product.code
                                  .toLowerCase()
                                  .includes(searchModalValue.toLowerCase())
                            )
                            .map((product) => (
                              <tr
                                className={styles.table_modal_tr}
                                key={product.id}
                              >
                                <th
                                  style={{
                                    cursor: "pointer",
                                    color: "#0066ff",
                                    wordBreak: 'break-word',
                                    minWidth: '150px'
                                  }}
                                  onClick={() => handleProductSelect(product)}
                                >
                                  {product.title}
                                </th>
                                <th
                                    style={{
                                      wordBreak: 'break-word',
                                      minWidth: '100px'
                                    }}
                                >
                                  {product.code}
                                </th>
                                <th>
                                  {product.image || product?.url_image ? (
                                    <div
                                      style={{
                                        height: "30px",
                                        textAlign: "center",
                                        width: "35px",
                                      }}
                                    >
                                      <img
                                        src={product.image || product?.url_image}
                                        alt=" "
                                        style={{
                                          width: "auto",
                                          maxWidth: "100%",
                                          maxHeight: "100%",
                                        }}
                                      />
                                    </div>
                                  ) : (
                                    <span>-</span>
                                  )}
                                </th>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    ) : (
                      <h5 className="mt-5 mb-3 text-center">
                        Ничего не найдено
                      </h5>
                    )
                  ) : (
                    <h5 className="mt-5 mb-3 text-center">Товаров нет</h5>
                  )}
                </div>
              </div>
            </ModalBody>
          </Modal>
        )}

        {isModalSupplierOpen && (
          <Modal isOpen={handleSupplierChange} toggle={handleSupplierClose}>
            <ModalHeader toggle={handleSupplierClose}>
              Выберите клиента
            </ModalHeader>

            <ModalBody>
              <div
                className={styles.header_modal}
                style={{ marginBottom: "15px" }}
              >
                <Button
                  onClick={handleAddSupplier}
                  color="success"
                  className="add-btn"
                  id="create-btn"
                >
                  <i className="ri-add-line align-bottom me-1"></i> Добавить
                  клиента
                </Button>
                <div className={styles.search_prod}>
                  <i className={`ri-search-line ${styles["add_prod"]}`}></i>
                  <input
                    style={{ border: "none" }}
                    placeholder="Поиск по клиентам..."
                    type="text"
                    onChange={onChangeModalValue}
                    value={searchModalValue}
                  />
                </div>
              </div>
              <div
                style={{ overflowY: "auto", height: "47vh", marginTop: "15px" }}
              >
                {supplier && supplier.length > 0 ? (
                  supplier.filter(
                    (supplier) =>
                      supplier.name
                        .toLowerCase()
                        .includes(searchModalValue.toLowerCase()) ||
                      supplier.phone
                        .toLowerCase()
                        .includes(searchModalValue.toLowerCase()) ||
                      supplier.address
                        .toLowerCase()
                        .includes(searchModalValue.toLowerCase())
                  ).length > 0 ? (
                    <table className={styles.table_customer}>
                      <thead>
                        <tr>
                          <th>Имя</th>
                          <th>Телефон</th>
                          <th>Адрес</th>
                        </tr>
                      </thead>
                      <tbody>
                        {supplier
                          .filter(
                            (supplier) =>
                              supplier.name
                                .toLowerCase()
                                .includes(searchModalValue.toLowerCase()) ||
                              supplier.phone
                                .toLowerCase()
                                .includes(searchModalValue.toLowerCase()) ||
                              supplier.address
                                .toLowerCase()
                                .includes(searchModalValue.toLowerCase())
                          )
                          .map((supplier) => (
                            <tr key={supplier.id}>
                              <td
                                className={styles.td_picker}
                                onClick={() => handleSupplierClick(supplier)}
                              >
                                {supplier.name}
                              </td>
                              <td>{supplier?.phone ? supplier.phone : "-"}</td>
                              <td>
                                {supplier?.address ? supplier.address : "-"}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  ) : (
                    <h5 className="mt-5 mb-3 text-center">Ничего не найдено</h5>
                  )
                ) : (
                  <h5
                    style={{ lineHeight: "1.6", color: "grey" }}
                    className="text-center mt-3"
                  >
                    Клиентов не найдено
                  </h5>
                )}
              </div>
            </ModalBody>
          </Modal>
        )}

        {isModalStatusOpen && (
          <Modal isOpen={true} toggle={handleStatusClose}>
            <ModalHeader toggle={handleStatusClose}>
              Настройка статусов
            </ModalHeader>

            <ModalBody>
              <Button
                onClick={handleAddStatus}
                color="success"
                className="add-btn"
                id="create-btn"
              >
                <i className="ri-add-line align-bottom me-1"></i> Добавить
                статус
              </Button>
              <div
                style={{ overflowY: "auto", height: "47vh", marginTop: "15px" }}
              >
                {statuses && statuses.length > 0 ? (
                  <ol style={{ width: "100%" }}>
                    {statuses.map((status, index) => (
                      <li
                        className={styles.li_statuses}
                        key={status.id}
                        style={{ display: "flex" }}
                      >
                        <div
                          className={styles.mark_block}
                          style={{
                            backgroundColor:
                              status?.mark === "positive"
                                ? "#99e8c0"
                                : status?.mark === "refusal"
                                ? "#f7acac"
                                : status?.mark === "neutral"
                                ? "#f7f1ac"
                                : "rgba(39,170,199,0.28",
                          }}
                        ></div>
                        <Input
                          maxLength={32}
                          value={status?.name}
                          onChange={(e) => {
                            const updatedStatuses = [...statuses];
                            if (e.target.value.length < 1) {
                              e.target.value = updatedStatuses[
                                index
                              ].name.slice(0, 1);
                            }
                            updatedStatuses[index].name = e.target.value;
                            setStatuses(updatedStatuses);
                          }}
                          onBlur={() => updateStatus(statuses[index])}
                        />
                        <select
                          value={status.mark || ""}
                          disabled={orderData?.status_obj?.id === status.id}
                          onChange={(e) => {
                            const updatedStatuses = [...statuses];
                            updatedStatuses[index].mark = e.target.value;
                            setStatuses(updatedStatuses);
                            updateStatus(updatedStatuses[index]);
                          }}
                          className={`form-select ${styles["select_width"]}`}
                        >
                          <option
                            className={styles.clear_option}
                            value="positive"
                          >
                            Позитивный
                          </option>
                          <option
                            className={styles.clear_option}
                            value="neutral"
                          >
                            Нейтральный
                          </option>
                          <option
                            className={styles.clear_option}
                            value="refusal"
                          >
                            Отказ
                          </option>
                        </select>
                        {orderData?.status_obj?.id !== status.id ? (
                          <div
                            className={styles.delete_inline}
                            onClick={() => handleDeleteStatus(status.id)}
                          >
                            <img src="/delete-inline.svg" alt="delete" />
                          </div>
                        ) : (
                          <div className={styles.delete_inline}></div>
                        )}
                      </li>
                    ))}
                  </ol>
                ) : (
                  <h5
                    style={{ lineHeight: "1.6", color: "grey" }}
                    className="text-center mt-3"
                  >
                    Статусов не найдено
                  </h5>
                )}
              </div>
            </ModalBody>
          </Modal>
        )}
        {isAddStatus && (
          <Modal
            className={styles.modal_smaller}
            isOpen={handleAddStatus}
            toggle={handleAddStatusClose}
          >
            <ModalHeader toggle={handleAddStatusClose}>
              Создать статус
            </ModalHeader>

            <ModalBody>
              <div className={styles.cart}>
                <Label htmlFor="articleInput" className="form-label">
                  <h6 className={styles.input_label}>Введите название</h6>
                </Label>
                <Input
                  type="text"
                  value={statusInputName}
                  onChange={handleStatusInputNameChange}
                  id="nameInput"
                  invalid={!!errors?.name}
                />
                {errors && errors?.name ? (
                  <FormFeedback className={styles.feedback}>
                    <span className="lh-1">{errors.name}</span>
                  </FormFeedback>
                ) : null}
              </div>
              <div className={styles.cart}>
                <Label htmlFor="articleInput" className="form-label">
                  <h6 className={styles.input_label}>Выберите метку статуса</h6>
                </Label>
                <select
                  style={{
                    backgroundColor:
                      statusInputMark === "positive"
                        ? "#b0edce"
                        : statusInputMark === "refusal"
                        ? "#f7acac"
                        : statusInputMark === "neutral"
                        ? "#f7f1ac"
                        : "rgba(47, 218, 255, 0.28)",
                  }}
                  value={statusInputMark}
                  onChange={handleStatusInputMarkChange}
                  className={`form-select`}
                >
                  <option className={styles.clear_option} value="positive">
                    Позитивный
                  </option>
                  <option className={styles.clear_option} value="neutral">
                    Нейтральный
                  </option>
                  <option className={styles.clear_option} value="refusal">
                    Отказ
                  </option>
                </select>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button onClick={addStatus} type="submit" color="primary">
                Сохранить
              </Button>{" "}
            </ModalFooter>
          </Modal>
        )}

        {isModalProductsOpen && (
          <Modal isOpen={isModalOpen} toggle={handleCloseAddProduct}>
            <form
              // onSubmit={handleSubmitProduct}
              onSubmit={(e) => {
                e.preventDefault();
                validationAddProduct.handleSubmit();
                return false;
              }}
            >
              <ModalHeader toggle={handleCloseAddProduct}>
                Создать товар
              </ModalHeader>
              <ModalBody>
                <div className="text-center">
                  <div className="position-relative d-inline-block">
                    <div className="position-absolute  bottom-0 end-0">
                      <Label htmlFor="customer-image-input" className="mb-0">
                        <div className="avatar-xs cursor-pointer">
                          <div className="avatar-title bg-light border rounded-circle text-muted">
                            <i className="ri-image-fill"></i>
                          </div>
                        </div>
                      </Label>
                      <Input
                        className="form-control d-none"
                        id="customer-image-input"
                        type="file"
                        name="image"
                        accept="image/png, image/gif, image/jpeg, image/webp"
                        onChange={(event) => {
                          const file = event.target.files[0];
                          if (file) {
                            setSelectedFile(file);
                            const imageUrl = URL.createObjectURL(file);
                            setSelectedImage(imageUrl);
                          } else {
                            setSelectedFile(null);
                            setSelectedImage("");
                          }
                          validationAddProduct.handleChange(event);
                        }}
                        onBlur={validationAddProduct.handleBlur}
                        // value={validation.values.image || ""}
                        value={""}
                        invalid={
                          validationAddProduct.touched.image &&
                          validationAddProduct.errors.image
                            ? true
                            : false
                        }
                      />
                    </div>
                    <div className="avatar-lg p-1">
                      <div className="avatar-title bg-light text-primary fs-12 lh-1">
                        {" "}
                        {/*rounded-circle */}
                        {selectedImage ? (
                          <img
                            src={selectedImage}
                            alt="картинка товара"
                            id="customer-img"
                            className="avatar-md object-fit-cover"
                          /> /*rounded-circle*/
                        ) : (
                          "выберите картинку товара"
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className={styles.cart_lg}>
                  <Label htmlFor="basicInput" className="form-label">
                    <h6>Наименование товара</h6>
                  </Label>
                  {/*<Input*/}
                  {/*  name="title"*/}
                  {/*  value={productData.title}*/}
                  {/*  onChange={handleChange}*/}
                  {/*  required*/}
                  {/*  type="text"*/}
                  {/*  className="form-control"*/}
                  {/*  id="basicInput"*/}
                  {/*/>*/}
                  <Input
                    name="title"
                    id="basicInput"
                    type="text"
                    className="form-control"
                    placeholder="Введите название..."
                    validate={{
                      required: { value: true },
                    }}
                    onChange={validationAddProduct.handleChange}
                    onBlur={validationAddProduct.handleBlur}
                    value={validationAddProduct.values.title || ""}
                    invalid={
                      validationAddProduct.touched.title &&
                      validationAddProduct.errors.title
                        ? true
                        : !!errors?.title
                    }
                  />
                  {validationAddProduct.touched.title &&
                  validationAddProduct.errors.title ? (
                    <FormFeedback type="invalid">
                      {validationAddProduct.errors.title}
                    </FormFeedback>
                  ) : errors && errors?.title ? (
                    <FormFeedback type="invalid">
                      <div>{errors.title}</div>
                    </FormFeedback>
                  ) : null}
                </div>

                <div className={styles.cart}>
                  <Label htmlFor="articleInput" className="form-label">
                    <h6>Артикул товара</h6>
                  </Label>
                  {/*<Input*/}
                  {/*  name="code"*/}
                  {/*  value={productData.code}*/}
                  {/*  onChange={handleChange}*/}
                  {/*  required*/}
                  {/*  type="number"*/}
                  {/*  id="articleInput"*/}
                  {/*/>*/}
                  <Input
                    name="code"
                    id="articleInput"
                    type="text"
                    className="form-control"
                    placeholder="Введите артикул..."
                    validate={{
                      required: { value: true },
                    }}
                    onChange={validationAddProduct.handleChange}
                    onBlur={validationAddProduct.handleBlur}
                    value={validationAddProduct.values.code || ""}
                    invalid={
                      validationAddProduct.touched.code &&
                      validationAddProduct.errors.code
                        ? true
                        : !!errors?.code
                    }
                  />
                  {validationAddProduct.touched.code &&
                  validationAddProduct.errors.code ? (
                    <FormFeedback type="invalid">
                      {validationAddProduct.errors.code}
                    </FormFeedback>
                  ) : errors && errors?.code ? (
                    <FormFeedback type="invalid">
                      <div>{errors.code}</div>
                    </FormFeedback>
                  ) : null}
                </div>

                <div className={styles.form_el}>
                  <div className={styles.cart}>
                    <Label htmlFor="costInput" className="form-label">
                      <h6>Цена продажи</h6>
                    </Label>
                    <Input
                      name="price"
                      id="costInput"
                      type="number"
                      className="form-control"
                      placeholder="Введите стоимость..."
                      validate={{
                        required: { value: true },
                      }}
                      onChange={validationAddProduct.handleChange}
                      onBlur={validationAddProduct.handleBlur}
                      value={validationAddProduct.values.price || ""}
                      invalid={
                        validationAddProduct.touched.price &&
                        validationAddProduct.errors.price
                          ? true
                          : !!errors?.price
                      }
                    />
                    {validationAddProduct.touched.price &&
                    validationAddProduct.errors.price ? (
                      <FormFeedback type="invalid">
                        {validationAddProduct.errors.price}
                      </FormFeedback>
                    ) : errors && errors?.price ? (
                      <FormFeedback type="invalid">
                        <div>{errors.price}</div>
                      </FormFeedback>
                    ) : null}
                  </div>
                </div>

                <div className={styles.cart_lg}>
                  <Label htmlFor="descriptionInput" className="form-label">
                    <h6>Описание</h6>
                  </Label>
                  <Input
                    name="description"
                    id="description-field"
                    type="textarea"
                    className="form-control"
                    placeholder="Введите описание для товара..."
                    validate={{
                      required: { value: true },
                    }}
                    onChange={validationAddProduct.handleChange}
                    onBlur={validationAddProduct.handleBlur}
                    value={validationAddProduct.values.description || ""}
                    invalid={
                      validationAddProduct.touched.description &&
                      validationAddProduct.errors.description
                        ? true
                        : !!errors?.description
                    }
                  />
                  {validationAddProduct.touched.description &&
                  validationAddProduct.errors.description ? (
                    <FormFeedback type="invalid">
                      {validationAddProduct.errors.description}
                    </FormFeedback>
                  ) : errors && errors?.description ? (
                    <FormFeedback type="invalid">
                      <div>{errors.description}</div>
                    </FormFeedback>
                  ) : null}
                </div>
              </ModalBody>
              <ModalFooter>
                <Button type="submit" color="success">
                  Сохранить товар
                </Button>{" "}
                <Button color="danger" onClick={handleCloseAddProduct}>
                  Закрыть
                </Button>
              </ModalFooter>
            </form>
          </Modal>
        )}
        {isAddSupplier && (
          <Modal
            className={styles.modal_smaller}
            isOpen={handleAddSupplier}
            toggle={handleAddSupplierClose}
          >
            <ModalHeader toggle={handleAddSupplierClose}>
              Создать клиента
            </ModalHeader>

            <ModalBody>
              <div className={styles.cart}>
                <Label htmlFor="articleInput" className="form-label">
                  <h6 className={styles.input_label}>Введите имя</h6>
                </Label>
                <Input
                  type="text"
                  value={supplierInputName}
                  onChange={handleSupplierInputNameChange}
                  id="nameInput"
                  invalid={!!errors?.name}
                />
                {errors && errors?.name ? (
                  <FormFeedback className={styles.feedback}>
                    <span className="lh-1">{errors.name}</span>
                  </FormFeedback>
                ) : null}
              </div>
              <div className={styles.cart}>
                <Label htmlFor="articleInput" className="form-label">
                  <h6 className={styles.input_label}>Введите Телефон</h6>
                </Label>
                <Input
                  type="text"
                  value={supplierInputPhone}
                  onChange={handleSupplierInputPhoneChange}
                  id="phoneInput"
                  invalid={!!errors?.phone}
                />
                {errors && errors?.phone ? (
                  <FormFeedback className={styles.feedback}>
                    <span className="lh-1">{errors.phone}</span>
                  </FormFeedback>
                ) : null}
              </div>
              <div className={styles.cart} style={{ width: "100%" }}>
                <Label htmlFor="articleInput" className="form-label">
                  <h6 className={styles.input_label}>Введите Адрес</h6>
                </Label>
                <Input
                  type="textarea"
                  value={supplierInputAddress}
                  onChange={handleSupplierInputAddressChange}
                  id="addressInput"
                  invalid={!!errors?.address}
                />
                {errors && errors?.address ? (
                  <FormFeedback className={styles.feedback}>
                    <span className="lh-1">{errors.address}</span>
                  </FormFeedback>
                ) : null}
              </div>
            </ModalBody>
            <ModalFooter>
              <Button onClick={addSupplier} type="submit" color="primary">
                Сохранить
              </Button>{" "}
            </ModalFooter>
          </Modal>
        )}
        <ToastContainer closeButton={false} />
      </Container>
    </div>
  );
};

export default DetailsOrder;
